import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.scss";
import "./assets/scss/main.scss";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import history from "./config/router/history";
import Header from './components/views/header/header';
import Footer from './components/views/footer/footer';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Routes from "./config/router/path";

/**
 * Bootstrap application router.
 * @return {string} JSX Template.
 */
function App() {
  const loggedIn = window.sessionStorage.getItem("loginUser");
  return (
    <div className={loggedIn ? '' : 'App'} id="App">
      <Router {...history}>
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <Switch>
            {Routes}
          </Switch>
          <Footer />
        </Suspense>
      </Router>
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
