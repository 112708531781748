import React from 'react';
import "./side-nav.scss";
import { RoleEnum } from '../../../common/constants/role-enum';

export const SideNavTemplate = function (this: any) {
    return (
        <div className={(this.state.iscollapsed) ? 'left-menu-panel d-flex align-items-stretch collapse' : 'left-menu-panel d-flex align-items-stretch'} >
            <div className="nav-info w-100">
                <div className="d-flex justify-content-end">
                    <div className="collpase-arrow-box">
                        <a id="slide" className={(this.state.iscollapsed) ? 'left-icon rotate' : ' left-icon'}
                            onClick={() => this.changePage()}></a>
                    </div>
                </div>
                <ul className="nav flex-column">
                {this.userInfo?.role !== RoleEnum.Admin ? <li className="nav-item">
                        <a className={(this.state.dashboardLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('dashboardLink')} href="javascript:void(0)">
                                <span className={(this.state.dashboardLink) ? 'home-icon-1 dark-icons' : 'home-icon-1'}></span>Dashboard</a>
                    </li> : ''}
                    {this.userInfo?.role === RoleEnum.Admin ? <li className="nav-item">
                        <a className={(this.state.advisorsLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('advisorsLink')} href="javascript:void(0)">
                                <span className={(this.state.advisorsLink) ? 'home-icon-1 dark-icons' : 'home-icon-1'}></span>Advisor</a>
                    </li> : ''}
                  { this.userInfo?.role !== RoleEnum.Student ? <li className="nav-item">
                        <a className={(this.state.cohortsLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('cohortsLink')}
                            href="javascript:void(0)"><span className={(this.state.cohortsLink) ? 'cohort-icon dark-icons' : 'cohort-icon '}></span>Cohorts</a>
                    </li> : ''}
                    { this.userInfo?.role !== RoleEnum.Student ? <li className="nav-item">
                        <a className={(this.state.sequncesLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('sequncesLink')} href="javascript:void(0)">
                            <span className={(this.state.sequncesLink) ? 'sequence-icon' : 'sequence-icon light-icons'}></span>Sequences</a>
                    </li> : ''}
                    { this.userInfo?.role !== RoleEnum.Student ?  <li className="nav-item">
                        <a className={(this.state.studentsLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('studentsLink')} href="javascript:void(0)">
                            <span className={(this.state.studentsLink) ? 'students-icon' : 'students-icon light-icons'}></span>Students</a>
                    </li> : ''}
                    { this.userInfo?.role !== RoleEnum.Student ?    <li className="nav-item">
                        <a className={(this.state.settingLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('settingLink')} href="javascript:void(0)">
                            <span className={(this.state.settingLink) ? 'setting-icon' : ' setting-icon light-icons'}> </span>Templates</a>
                    </li> :''}
                    { this.userInfo?.role !== RoleEnum.Admin ?    <li className="nav-item">
                        <a className={(this.state.requestsLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('requestsLink')} href="javascript:void(0)">
                            <span className={(this.state.requestsLink) ? 'request-icon' : 'request-icon light-icons'}> </span>
                            <span className="d-flex justify-content-between align-items-center mr-0 w-100">
                                <span>My Requests</span> 
                                <span className="badge mr-0">{this.state.requestsData?.length}</span>
                            </span>
                             
                            </a>
                    </li> :''}
                       <li className="nav-item">
                        <a className={(this.state.supportLink) ? 'nav-link d-flex align-items-center active' : 'nav-link d-flex align-items-center'}
                            onClick={() => this.changePage('supportLink')} href="javascript:void(0)">
                            <span className={(this.state.supportLink) ? 'support-icon' : ' support-icon light-icons'}> </span>Support</a>
                    </li> 
                </ul>
            </div>
        </div>

    )
};
