/* eslint-disable */
import { BehaviorSubject } from 'rxjs';
import { API_URL } from '../constants/url';
import { ToasterService } from './toastr-service';
import { emailRegEx } from '../constants/regex';
export class DataService {

  constructor() { }
  private toasterService = new ToasterService();
  public cohortData = new BehaviorSubject(undefined);

  public setCohortData(data: any) {
    this.cohortData.next(data);
  }

  public formatNumber(value: string) {
    let number = (value).trim().replace(/\D/g, '');
    if (number.length <= 12) {
      if (number.length <= 3) {
        return number.substr(0, 3);
      } else if (number.length <= 6) {
        return [number.substr(0, 3), number.substr(3, 3)].join('-');
      } else {
        return [number.substr(0, 3), number.substr(3, 3), number.substr(6, 4)].join('-');
      }
    } else {
      return '';
    }
  }

  public verifyEmail(sendEmail: boolean, stateData: any) {
    if (stateData.username && emailRegEx.test(stateData.username)) {
      const requestData = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: stateData.username, sendEmail })
      };
      fetch(API_URL + 'sendotp', requestData)
        .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const resdata = isJson && await response.json();
          if (resdata && resdata.payload) {
            if (sendEmail) {
              this.toasterService.notify('success', 'Verification Code sent to  ' + stateData.username);
            }
            setTimeout(() => {
               window.location.href = '/reset-password/' + btoa(JSON.stringify({email:stateData.username,case:1}));
            }, 3000);
          } else {
            this.toasterService.notify('error', 'User Does not exists in the database.');
          }
        })
        .catch(error => {

        });
    } else {
      this.toasterService.notify('error', 'Invalid username');
    }
  }

}