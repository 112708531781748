/* eslint-disable */
import React, { useState, Component } from 'react';
import { SettingsTemplate } from './settings-template';
import { API_URL } from '../../../common/constants/url';
import { ToasterService } from '../../../common/services/toastr-service';
import { HttpService } from '../../../common/services/http-service';
import { httpMethods } from '../../../common/constants/httpEnum';

export default class Students extends Component {
  public toasterService = new ToasterService();
  public loggedIn = window.sessionStorage.getItem("loginUser");
  public httpService = new HttpService();
  public userInfo = this.loggedIn &&
    typeof this.loggedIn === 'string' ? JSON.parse(this.loggedIn).userInfo : '';
  constructor(props: any) {
    super(props);
    this.setText = this.setText.bind(this);
    this.editViewTemplate = this.editViewTemplate.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.state = {
      text: '',
      disableEditor: false,
      loader: false,
      templateData: [],
      showTemplate: false,
      templateName: ''

    }
  }
  componentDidMount() {
    const stateData: any = this.state;
    if (!stateData.templateData.length) {
      this.getTemplateData();
    }
  }

  public getTemplateData() {
    this.setState({ loader: true });
    const requestData = { email: this.userInfo?.email };
    fetch(API_URL + 'gettemplatedata', 
     this.httpService.httpInterceptor(httpMethods.POST,requestData))
      .then(async response => {
        this.setState({ loader: false });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();
        if (resdata && resdata.payload) {
          this.setState({ templateData: resdata.payload.templates });
        }
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  public setText(value: any) {
    this.setState({ text: value });
  }

  public editViewTemplate(rowData: any, rowIndex?: number) {
    this.setState({
      text: rowData.htmlString,
      disableEditor: (rowIndex || rowIndex === 0) ? false : true,
      templateName: rowData.templateName,
      showTemplate: true
    });

  }

  public saveTemplate() {
    const stateData: any = this.state;
    stateData.templateData.map((e:any)=>{ 
      if(e.templateName === stateData.templateName) {
        e.htmlString = stateData.text
      }});
    this.setState({ loader: true });
    const requestData = {
        templates: stateData.templateData,
        email: this.userInfo?.email
    };
    fetch(API_URL + 'savetemplate', 
    this.httpService.httpInterceptor(httpMethods.POST,requestData))
      .then(async response => {
        this.setState({ loader: false });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();
        if (data && data.payload) {
          this.toasterService.notify('success', 'Template Changed SuccessFully');
          this.setState({
            templateData: data.payload.templates, showTemplate: false, selectedTemplate: {
              templateName: ''
            }
          });
        } else {
          this.toasterService.notify('error', 'Failed to Change the Template');
        }
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  render() {
    return SettingsTemplate.call(this);
  }
}

