/* eslint-disable */
import React, { useState, Component } from 'react';
import { httpMethods } from '../../../common/constants/httpEnum';
import { API_URL } from '../../../common/constants/url';
import { HttpService } from '../../../common/services/http-service';
import { ToasterService } from '../../../common/services/toastr-service';
import { SupportTemplate } from './support-template';


export default class Support extends Component {
  public loggedIn: any = window.sessionStorage.getItem("loginUser");
  public userInfo = this.loggedIn ? JSON.parse(this.loggedIn).userInfo : '';
  public httpService = new HttpService();
  public toasterService = new ToasterService();
  constructor(props: any) {
    super(props);
   
    this.state = {
      comments:''
    }
  }


public sendFormDataToEmail() {
  console.log('here');
  const stateData :any = this.state;
  const reqObj = {
    name: this.userInfo?.fullName ,
    email: this.userInfo?.email,
    phoneNumber:  this.userInfo?.phoneNumber ,
    comments: stateData.comments
  }
  fetch(API_URL + 'sendFormDataToEmail',
  this.httpService.httpInterceptor(httpMethods.POST,reqObj)) 
  .then(async response => {
    if (!response.ok && !response.headers) {
        throw Error(response.statusText);
    }
    const isJson = response.headers.get('content-type')?.includes('application/json');
    const data = isJson && await response.json();
    if (data) {
        this.toasterService.notify('success', 'Successfully Sent');
      this.setState({comments:''});
    } 
})
}

  render() {
    return SupportTemplate.call(this);
  }
}

