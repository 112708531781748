/* eslint-disable */

import React, { Component } from 'react';
import { AddAdvisorTemplate } from './add-advisor-template';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import { API_URL } from '../../../../../common/constants/url';
import {emailRegEx , nameRegEx} from '../../../../../common/constants/regex';
import history from '../../../../../config/router/history';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { DataService } from '../../../../../common/services/data-service';
import { httpMethods } from '../../../../../common/constants/httpEnum';
import { HttpService } from '../../../../../common/services/http-service';
export default class AddAdvisor extends Component {
    public dataService = new DataService();
    public loggedIn = window.sessionStorage.getItem("loginUser");
    public editData = this.loggedIn && JSON.parse(this.loggedIn).editData ?
        JSON.parse(this.loggedIn).editData : {};
        public httpService = new HttpService();
    public toasterService = new ToasterService();
    constructor(props: any) {
        super(props);
        this.onchanges = this.onchanges.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            zoomId:'',
            errormessage: '',
            phoneNumber:'',
            _id: null
        }
    }
    componentDidMount() {
        const stateData: any = this.state;
        if (this.editData.editAdvisor) {
            let result = this.editData.editAdvisor;
            let obj: any = {};
            obj["_id"] = result._id ? result._id : null;
            obj["firstName"] = result.firstName ? result.firstName : null;
            obj["lastName"] = result.lastName ? result.lastName : null;
            obj["email"] = result.email ? result.email : null;
            obj["zoomId"] = result.zoomId ? result.zoomId : null;
            obj["phoneNumber"] = result.phoneNumber ? result.phoneNumber : null;
            this.setState(obj);

        }

    }
    public gotToAdvisors() {
        history.push('/advisors-main');
        window.location.reload();
    }

    public onchanges(event: any) {
        switch (event.target.name) {
            case 'email': this.setState({ email: event.target.value }); break;
            case 'zoomId': this.setState({ zoomId: event.target.value }); break;
            case 'firstName': this.setState({ firstName: event.target.value }); break;
            case 'lastName': this.setState({ lastName: event.target.value }); break;
            case 'phoneNumber':
                this.setState({ phoneNumber: this.dataService.formatNumber(event.target.value)});
            break;

        }
    }

    public submitForm(event: any) {
        event.preventDefault();
        let stateData: any = this.state;
        if (stateData && stateData.email && nameRegEx.test(stateData.firstName) && 
            emailRegEx.test(stateData.email) && emailRegEx.test(stateData.zoomId)) {

            const requestData = {
            
                    _id: stateData._id ? stateData._id : null,
                    fullName: stateData.firstName + ' ' + stateData.lastName,
                    firstName: stateData.firstName,
                    lastName: stateData.lastName,
                    phoneNumber: stateData.phoneNumber ,
                    email: stateData.email,
                    zoomId: stateData.zoomId,
            
            };
            fetch(API_URL + 'addadvisor', this.httpService.httpInterceptor(httpMethods.POST,requestData))
                .then(async response => {
                    if (!response.ok && !response.headers) {
                        throw Error(response.statusText);
                    }
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    if (data && data.payload && !data.error) {
                        this.toasterService.notify('success', 'Advisor Successfully Added');
                        this.gotToAdvisors();
                    } else if (data.error) {
                        this.toasterService.notify('error', data.error);
                    } else {
                        this.toasterService.notify('error', 'Failed to Add Advisor');
                    }
                })
                .catch(error => {
                    //   this.toasterService.notify('error', 'Login Failure');
                });
        } else if (stateData.email && !emailRegEx.test(stateData.email)) {
            this.toasterService.notify('error', 'Please Enter a Valid Email Id');
        } else if (stateData.zoomId && !emailRegEx.test(stateData.zoomId)) {
            this.toasterService.notify('error', 'Please Enter a Valid Zoom Id');
        } else if (stateData.firstName && !nameRegEx.test(stateData.firstName)) {
            this.toasterService.notify('error', 'Please Enter A valid name');
        } else {
            this.toasterService.notify('error', 'Please fill all the fields');
        }
    }

    render() {

        return AddAdvisorTemplate.call(this);
    }

}