import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './RestrictedRoute';
import login from '../../components/views/login/login';
import cohort from '../../components/views/cohort/components/cohort-main/cohort-main';
import AddCohort from '../../components/views/cohort/components/add-cohort/add-cohort';
import Dashboard from '../../components/views/dashboard/dashboard';
import StudentsMain from '../../components/views/students/components/students-main/students-main';
import AddStudent from '../../components/views/students/components/add-student/add-student';
import AdvisorsMain from '../../components/views/advisors/components/advisors-main/advisors-main';
import AddAdvisor from '../../components/views/advisors/components/add-advisor/add-advisor';
import AddSequence from '../../components/views/sequences/components/add-sequence/add-sequence';
import SequenceMain from '../../components/views/sequences/components/sequence-main/sequence-main';
import Settings from '../../components/views/settings/settings';
import ForgotPassword from '../../components/views/forgot-password/forgot-password';
import ResetPassword from '../../components/views/reset-password/reset-password';
import CreatePassword from '../../components/views/create-password/create-password';
import Profile from '../../components/views/profile/profile';
import Requests from '../../components/views/requests/requests';
import Support from '../../components/views/support/support';
import SessionTimeout from '../../components/views/session-timeout';

export default  [

    <Route path="/" exact strict component={login} key="login" />,
    <PrivateRoute path="/cohort-main" exact component={cohort} key="cohort-main" />,
    <PrivateRoute path="/add-cohort" exact component={AddCohort} key="add-cohort" />,
    <PrivateRoute path="/dashboard" exact component={Dashboard} key="dashboard" />, ,
    <PrivateRoute path="/students-main" exact component={StudentsMain} key="students" />,
    <PrivateRoute path="/add-student" exact component={AddStudent} key="add-student" />,
    <PrivateRoute path="/advisors-main" exact component={AdvisorsMain} key="advisors-main" />,
    <PrivateRoute path="/add-advisor" exact component={AddAdvisor} key="add-advisor" />,
    <PrivateRoute path="/add-sequence" exact component={AddSequence} key="add-sequence" />,
    <PrivateRoute path="/sequence-main" exact component={SequenceMain} key="sequence-main" />,
    <PrivateRoute path="/settings" exact component={Settings} key="settings" />,
    <PrivateRoute path="/profile" exact component={Profile} key="profile" />,
    <PrivateRoute path="/requests" exact component={Requests} key="requests" />,
    <PrivateRoute path="/support" exact component={Support} key="support" />,
    <Route path="/session-timeout" exact component={SessionTimeout} key="session-timeout" />,
    <Route path="/forgot-password" exact component={ForgotPassword} key="forgot-password" />,
    <Route path="/reset-password/:username" exact component={ResetPassword} key="reset-password/:username" />,
    <Route path="/create-password/:username" exact component={CreatePassword} key="create-password/:username" />,


];