/* eslint-disable */
import React, { Component } from 'react';
import { ProfileTemplate } from './profile-template';
import { API_URL } from '../../../common/constants/url';
import { ToasterService } from '../../../common/services/toastr-service';
import { DataService } from '../../../common/services/data-service';
import { RoleEnum } from '../../../common/constants/role-enum';
import { httpMethods } from '../../../common/constants/httpEnum';
import { HttpService } from '../../../common/services/http-service';


export default class Profile extends Component {
    public dataService = new DataService();
    public httpService = new HttpService();
    public toasterService = new ToasterService();
    public loggedIn: any = window.sessionStorage.getItem("loginUser");
    public userInfo = this.loggedIn ? JSON.parse(this.loggedIn).userInfo : '';
    public calendarList = [{name: 'Outlook', key: 'Outlook'}, 
    {name: 'Google Calender', key: 'Google Calender'}];

    public masterData: any = {};
    constructor(props: any) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onchanges = this.onchanges.bind(this);
        this.state = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            selectedCalendar:'',
            iFrame:''
        }
    }

    componentDidMount() {
        const stateData: any = this.state;
        this.setState({ loader: true });
        const requestData = {'_id': this.userInfo._id };
        fetch(API_URL + 'getuserbyid', 
         this.httpService.httpInterceptor(httpMethods.POST, requestData))
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();

                if (resdata && resdata.payload) {
                    let obj: any = {};
                    obj["email"] = resdata.payload.email ? resdata.payload.email : '';
                    obj["firstName"] = resdata.payload.firstName ? resdata.payload.firstName : '';
                    obj["lastName"] = resdata.payload.lastName ? resdata.payload.lastName : '';
                    obj["phoneNumber"] = resdata.payload.phoneNumber ? resdata.payload.phoneNumber : '';
                    obj['iFrame'] = resdata.payload.iFrame ? resdata.payload.iFrame : '';
                    obj['selectedCalendar'] = resdata.payload.selectedCalendar ? resdata.payload.selectedCalendar : ''; 
                    this.setState(obj);
                    if (obj) {
                        this.masterData = JSON.parse(JSON.stringify(obj));
                    }
                }
            })
            .catch(error => {
                this.setState({ loader: false });
            });







    }


    public onchanges(event: any) {
        switch (event.target.name) {
            case 'email': this.setState({ email: event.target.value }); break;
            case 'firstName': this.setState({ firstName: event.target.value }); break;
            case 'lastName': this.setState({ lastName: event.target.value }); break;
            case 'iFrame': this.setState({ iFrame: event.target.value }); break;
            case 'phoneNumber':
                        this.setState({ phoneNumber: this.dataService.formatNumber(event.target.value)});
                    break;
        }
    }

    public submitForm(): any {
        this.setState({ loader: true });
        const stateData:any = this.state;
        if(stateData.firstName && stateData.lastName && stateData.phoneNumber) {
          if(this.userInfo.role=== RoleEnum.Advisor && stateData.selectedCalendar === 'Google Calender'
                && (!stateData.iFrame || stateData.iFrame.toString().match(/iframe/g) === null)) {
                    this.toasterService.notify('error', 'Please Provide a Valid Calendar Link');
                    return;
                }
            fetch(API_URL + 'saveuserdata', 
              this.httpService.httpInterceptor(httpMethods.POST, this.state))
                .then(async response => {
                    this.setState({ loader: false });
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const resdata = isJson && await response.json();
    
                    if (resdata && resdata.payload) {
                        this.toasterService.notify('success', 'Profile Successfully Updated');
                        let copyloggedIn = this.loggedIn;
                        copyloggedIn['userInfo'] = resdata.payload;
                        window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
                    }
                })
                .catch(error => {
                    this.setState({ loader: false });
                });
        }else{
            this.setState({ loader: false });
            this.toasterService.notify('error', 'Please fill all the fields');
        }
      
    }

    public onFormCancel() {
        window.location.href = '/dashboard';
    }

    render() {
        return ProfileTemplate.call(this);
    }
}
