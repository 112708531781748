

/* eslint-disable */
import React, { useState, Component } from 'react';
import { SequenceMainTemplate } from './sequence-main-template';
import history from '../../../../../config/router/history';
import { API_URL } from '../../../../../common/constants/url';
import _ from 'lodash';
import { DateFormatService } from '../../../../../common/services/date-format-service';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { confirmAlert } from 'react-confirm-alert';
import { httpMethods } from '../../../../../common/constants/httpEnum';
import { HttpService } from '../../../../../common/services/http-service';
var momenttz = require('moment-timezone');
var moment = require('moment');

export default class SequenceMain extends Component {
    public toasterService = new ToasterService();
    public httpService = new HttpService();
    public loggedIn = window.sessionStorage.getItem("loginUser");
    public dateFormatService = new DateFormatService();
    constructor(props: any) {
        super(props);
        this.state = {
            loader: false,
            sequenceData: []
        }
        this.deleteSequence = this.deleteSequence.bind(this);
        let copyloggedIn: any = this.loggedIn;
        if (_.isString(copyloggedIn)) {
            copyloggedIn = JSON.parse(copyloggedIn);
        }
        copyloggedIn["editData"]["editSequence"] = null;
        window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
    }

    public addSequence() {
        history.push('/add-sequence');
        window.location.reload();
    }
    componentDidMount() {
        const stateData: any = this.state;
        if (!stateData.sequenceData.length) {
            this.getSequencesData();
        }
    }

    public transformDate(events: any) {
        if (events.length) {
            const obj: any = _.minBy(events, 'dateOfEvent');
            return this.dateFormatService.convertData(obj["dateOfEvent"]);
        }
    }
    public deleteSequence(id: number, rowIndex: number,events?:any) {
        if (events.length) {
            const obj1: any = _.maxBy(events, 'endTimeOfEvent');
            const obj2: any = _.minBy(events, 'dateOfEvent');
         if(new Date() < new Date(obj1["endTimeOfEvent"])  && 
         new Date() > new Date(obj2["dateOfEvent"])){
            this.toasterService.notify('error', 'Sequence is a part of an Active Cohort');
            return;
         }else{
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='conformation-popup'>
                            <h1>Are you sure?</h1>
                            <p>You want to delete this item?</p>
                            <button className="confirm-btn" onClick={onClose}>No</button>
                            <button className="confirm-btn"
                                onClick={() => {
                                    this.setState({ loader: true });
                                    const stateData: any = this.state;
                                    if (stateData.sequenceData.length) {
    
                                        fetch(API_URL + 'deletesequence',
                                            this.httpService.httpInterceptor(httpMethods.POST,
                                                { _id: id ? id : null }))
                                            .then(async response => {
                                                this.setState({ loader: false });
                                                if (!response.ok && !response.headers) {
                                                    throw Error(response.statusText);
                                                }
                                                const isJson = response.headers.get('content-type')?.includes('application/json');
                                                const data = isJson && await response.json();
                                                if (data && data.payload) {
                                                    this.toasterService.notify('success', 'Sequence Successfully Deleted');
                                                    stateData.sequenceData.splice(rowIndex, 1);
                                                    this.setState({ sequenceData: [...stateData.sequenceData] });
                                                    onClose();
                                                } else {
                                                    this.toasterService.notify('error', 'Failed to Delete Sequence');
                                                    onClose();
                                                }
                                            })
                                            .catch(error => {
                                                this.setState({ loader: false });
                                                //   this.toasterService.notify('error', 'Login Failure');
                                            });
                                    } else {
                                        this.setState({ loader: false });
                                    }
                                }}>
                                Yes, Delete it!
                            </button>
                        </div>
                    );
                }
            });
        }
        }
        
       
    }
    public editSequence(rowData: any) {
        if (rowData) {
            let copyloggedIn: any = this.loggedIn;
            if (_.isString(copyloggedIn)) {
                copyloggedIn = JSON.parse(copyloggedIn);
            }
            copyloggedIn["editData"]["editSequence"] = { ...rowData };
            window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
            history.push('/add-sequence');
            window.location.reload();
        }
    }
    public getSequencesData() {
        this.setState({ loader: true });
        fetch(API_URL + 'getSequencesData',
            this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();
                if (resdata && resdata.payload) {
                    var payload = resdata.payload;

                    for (let i = 0; i < payload.length; i++) {
                        if (payload[i].events && payload[i].events.length) {
                            for (let j = 0; j < payload[i].events.length; j++) {
                                payload[i].events[j].startTimeOfEvent = this.revrseTzConverter(payload[i].events[j].startTimeOfEvent, payload[i].events[j].tz);
                                payload[i].events[j].endTimeOfEvent = this.revrseTzConverter(payload[i].events[j].endTimeOfEvent, payload[i].events[j].tz);
                            }
                        }
                    }
                    this.setState({ sequenceData: [...payload] });
                }
            })
            .catch(error => {
                this.setState({ loader: false });
            });
    }

    public revrseTzConverter(timeOfEvent: any, targetTz: any) {
        const mom = moment(timeOfEvent);
        const sourceOffset = moment().utcOffset();
        const trgtOffset = moment.tz(targetTz).utcOffset();
        var diff = trgtOffset - sourceOffset;
        const newTime = mom.add(diff, 'minutes');
        return new Date(newTime.valueOf());
    }

    render() {
        return SequenceMainTemplate.call(this);
    }
}