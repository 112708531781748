
import { toast } from 'react-toastify';

/* eslint-disable */
export class ToasterService {

    constructor() { }

    public notify(type: any, text: any) {
        switch (type) {
            case 'success':
                return toast.success(text);
            case 'error':
                return toast.error(text)

        }
    }


}

