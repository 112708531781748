/* eslint-disable */

import React, { Component } from 'react';
import { logout, isLogin } from '../../../../../utils';
import { Link } from 'react-router-dom';
import { AddCohortTemplate } from './add-cohort-template';
import { API_URL, PROD_URL } from '../../../../../common/constants/url';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import history from '../../../../../config/router/history';
import { DataService } from '../../../../../common/services/data-service';
import { DateFormatService } from '../../../../../common/services/date-format-service';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { Checkbox } from 'primereact/checkbox';
import { RoleEnum } from '../../../../../common/constants/role-enum';
import { HttpService } from '../../../../../common/services/http-service';
import { httpMethods } from '../../../../../common/constants/httpEnum';
var momenttz = require('moment-timezone');
var moment = require('moment');

export default class AddCohort extends Component {
    public toasterService = new ToasterService();
    public httpService = new HttpService();
    public loggedIn = window.sessionStorage.getItem("loginUser");
    public dateFormatService = new DateFormatService();
    public userInfo = this.loggedIn ? JSON.parse(this.loggedIn).userInfo : '';
    public API_URL = API_URL;
    public PROD_URL = PROD_URL;
    public dataService = new DataService();
    public editData = this.loggedIn && JSON.parse(this.loggedIn).editData ?
        JSON.parse(this.loggedIn).editData : {};
    constructor(props: any) {
        super(props);
        this.onchanges = this.onchanges.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.getStudentsData = this.getStudentsData.bind(this);
        this.openStudentsPopup = this.openStudentsPopup.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setTime = this.setTime.bind(this);
        this.state = {
            loader: false,
            isLogin: isLogin(),
            showPopup: false,
            link: '',
            isCopied: false,
            Advisor: '',
            ZoomId: '',
            StartDate: new Date(),
            CohortName: '',
            Sequence: '',
            errormessage: '',
            enableLink: false,
            advisorsData: [],
            advisorOptions: [],
            sequenceData: [],
            sequenceOptions: [],
            _id: null,
            studentsData: [],
            showStudents: false,
            selectedStudents: null,
            showEvents: false,
            eventList: [],
            prestineStudentIds: []
        }
    }

    componentDidMount() {
        this.setState({ loader: true });
        const stateData: any = this.state;
        if (!stateData.advisorsData.length) {
            this.getAdvisorsData();
        }
        if (!stateData.sequenceData.length) {
            this.getSequencesData()
        }
        if (!stateData.studentsData.length) {
            this.getStudentsData();
        }
        
        if (this.editData.editCohort) {
            let result = this.editData.editCohort;
            let obj: any = {};
            obj["_id"] = result._id ? result._id : null;
            obj["Advisor"] = result.advisorEmail ? result.advisorEmail : null;
            obj["ZoomId"] = result.zoomId ? result.zoomId : null;
            obj["CohortName"] = result.cohortName ? result.cohortName : null;
            obj["StartDate"] = result.startDate ? new Date(result.startDate) : null;
            obj["EndDate"] = result.startDate ? new Date(result.endDate) : null;
            obj["Sequence"] = result.sequence ? result.sequence : null;
            obj["selectedStudents"] = result.selectedStudents ? result.selectedStudents : null;
            obj["prestineStudentIds"] = result.selectedStudents ? result.selectedStudents.map((dat: { _id: any; }) => dat._id) : [];
            this.setState(obj);

        }
        this.setState({ loader: false });

    }

    public getStudentsData() {

        fetch(API_URL + 'getStudentsData', this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();
                if (resdata && resdata.payload) {
                    let studentsData = JSON.parse(JSON.stringify(resdata.payload));
                   
                    if(this.editData.editCohort){
                        studentsData =  studentsData.filter((x:any)=> !x.cohortCode  ||  x.cohortCode  === this.editData.editCohort.cohortCode  ) ;  
                    }else{
                       
                    studentsData =  studentsData.filter((x:any)=> !x.cohortCode ||  (
                            x.cohortDetails && x.cohortDetails['cohortEndDate'] && 
                            new Date(x.cohortDetails['cohortEndDate']) < new Date())) ;
                    }
                   
                  
                    studentsData.map((e: any) => { e["isChecked"] = false });
                    this.setState({ studentsData: [...studentsData] });
                }
            })
            .catch(error => {
                //   notify('error', 'Login Failure');
            });
    }

    public openStudentsPopup() {
        this.setState({ showStudents: true });
    }

    public gotToCohorts() {
        history.push('/cohort-main');
        window.location.reload();
    }

    public getAdvisorsData() {

        fetch(API_URL + 'getAdvisorsData', this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();

                if (resdata && resdata.payload) {
                    let advisorOptions: any = [];
                    resdata.payload.map((e: any) => {
                        advisorOptions.push({ label: e.fullName, value: e.email });
                    });
                    this.setState({ advisorsData: [...resdata.payload], advisorOptions: advisorOptions });
                }
            })
            .catch(error => {
                //   notify('error', 'Login Failure');
            });
    }

    public onchanges(event: any, date?: any) {
       

        if (date) {
            this.setState({ StartDate: event.value });
        } else {
            const stateData: any = this.state;
            if (event.target.name === 'sequence' && stateData.sequenceData.length) {
                const event1 = stateData.sequenceData.find((x: any) => x.sequence === event.target.value);
                if (event1) {

                    const maxDateObj: any = _.maxBy(event1["events"], 'dateOfEvent');

                    this.setState({ EndDate: new Date(maxDateObj["endTimeOfEvent"]) })
                }
            }
            switch (event.target.name) {
                case 'advisor': {
                    this.setState({ Advisor: event.target.value });
                    let advsor = stateData.advisorsData.find((x: any) => x.email.toLowerCase() === event.target.value.toLowerCase());
                    if (!(advsor.zoomId && advsor.zoomId != null)) {
                        this.toasterService.notify('error', 'Advisor doesnot have zoom Id');
                    } 
                    this.setState({ ZoomId: advsor.zoomId });
                    
                } 
                break;
                case 'cohort': this.setState({ CohortName: event.target.value }); break;
                case 'sequence':
                    let events = stateData.sequenceData.find((x: any) => x.sequence === event.target.value).events;
                    this.setState({ Sequence: event.target.value, eventList: events }); break;
            }
        }

    }

    public showPopUp(link: any) {
        this.setState({ link: link, isCopied: false, showPopup: true });
    }
    public closePopup() {
        this.setState({ link: '', isCopied: false, showPopup: false });
    }

    public getSequencesData() {

        fetch(API_URL + 'getSequencesData', this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();
                if (resdata && resdata.payload) {
                    // if (!this.editData || !this.editData.editCohort) {
                        let resDataClone:any[] = JSON.parse(JSON.stringify(resdata.payload));
                        var payload = resDataClone;

                        for (let i = 0; i < payload.length; i++) {
                            if (payload[i].events && payload[i].events.length) {
                                for (let j = 0; j < payload[i].events.length; j++) {
                                    payload[i].events[j].startTimeOfEvent = this.revrseTzConverter(payload[i].events[j].startTimeOfEvent, payload[i].events[j].tz);
                                    payload[i].events[j].endTimeOfEvent = this.revrseTzConverter(payload[i].events[j].endTimeOfEvent, payload[i].events[j].tz);
                                }
                            }
                        }

                        var payloadClone = JSON.parse(JSON.stringify(payload));

                        // payload.map((item: any, index: number) => {
                        //     let obj: any = _.maxBy(item.events, 'endTimeOfEvent');
                        //     if (obj["endTimeOfEvent"] < new Date(moment().add(-24, 'hours'))) {
                        //         payloadClone.splice(index, 1);
                        //     }
                        // });
                    // }
                    let sequenceOptions: any = [];
                    payloadClone.map((e: any) => {
                        sequenceOptions.push({ label: e.sequence, value: e.sequence });
                    });
                    if (this.editData.editCohort) {
                        let events =  payloadClone.find((x: any) => x.sequence === this.editData.editCohort["sequence"]).events;
                        this.setState({ eventList:events});
                    }
                   
                    this.setState({ sequenceData: [...payloadClone], sequenceOptions: sequenceOptions });
                }
            })
            .catch(error => {
            });
    }

    public copyText(text: any) {
        var input: any = document.body.appendChild(document.createElement("input"));
        input.value = text;
        input.focus();
        input.select();
        document.execCommand('copy');
        input.parentNode.removeChild(input);
        this.setState({ isCopied: true });
        this.toasterService.notify('success', 'Copied');
    }




    public submitForm(event: any) {
        this.setState({ loader: true });
        event.preventDefault();
        let stateData: any = this.state;
        if (stateData && stateData.CohortName && stateData.StartDate) {
            const [AtoZ, t09] = ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', '0123456789'];
            let cohortCode = stateData._id ? null : _.sample(AtoZ) + '' + _.sample(AtoZ) +
                '' + _.sample(t09) + '' + _.sample(t09);
            let temp = stateData.Advisor ? stateData.advisorsData.find((x: any) => x.email.toLowerCase() === stateData.Advisor.toLowerCase()) :
                stateData.advisorsData.find((x: any) => x.email.toLowerCase() === this.userInfo.email.toLowerCase());
            ;
            let studentPhoneNumbers: any[] = [];

            if (stateData.selectedStudents.length) {
                _.map(stateData.selectedStudents.filter((dat: any)=> {return !(stateData.prestineStudentIds.includes(dat._id))}), 'phoneNumber').map((item: any) => {
                    if (item) {
                        studentPhoneNumbers.push(item.toString().replace(/\D/g, ''));
                    }
                });

                stateData.selectedStudents = stateData.selectedStudents.map((item: any) => {
                    if (!stateData.prestineStudentIds.includes(item._id)) {
                        item.isNew = true;
                    } else {
                        item.isNew = false;
                    }
                    return item;
                });
            }

            //revert the time format
            let sequenceClone: any[] = JSON.parse(JSON.stringify(stateData.sequenceData));
            for (let i = 0; i < sequenceClone.length; i++) {
                for (let index = 0; index < sequenceClone[i].events.length; index++) {
                    const element = sequenceClone[i].events[index];
                    sequenceClone[i].events[index].startTimeOfEvent = this.tzConverter(element.startTimeOfEvent, element.tz);
                    sequenceClone[i].events[index].endTimeOfEvent = this.tzConverter(element.endTimeOfEvent, element.tz);
                }
            }

            //revert the time format
            let sequenceEventsClone: any[] = JSON.parse(JSON.stringify(stateData.eventList));
            for (let i = 0; i < sequenceEventsClone.length; i++) {
                const element = sequenceEventsClone[i];
                sequenceEventsClone[i].startTimeOfEvent = this.tzConverter(element.startTimeOfEvent, element.tz);
                sequenceEventsClone[i].endTimeOfEvent = this.tzConverter(element.endTimeOfEvent, element.tz);
            }

            const requestData = {
                    email: this.userInfo?.email,
                    role: this.userInfo?.role ? this.userInfo?.role : RoleEnum.Advisor,
                    cohortName: stateData.CohortName,
                    cohortCode: cohortCode,
                    advisorName: (stateData.advisorsData.length && temp && temp["fullName"]) ? temp["fullName"] : ((temp && temp["fullName"]) ? temp["fullName"] : ''),
                    advisorEmail: stateData.Advisor ? stateData.Advisor : this.userInfo.email,
                    advisorZoomId: stateData.ZoomId ? stateData.ZoomId : this.userInfo.zoomId,
                    sequence: stateData.Sequence,
                    startDate: stateData.StartDate,
                    endDate: stateData.EndDate,
                    studentPhoneNumbers: studentPhoneNumbers.length ? studentPhoneNumbers : [],
                    _id: stateData._id ? stateData._id : null,
                    studentIds: stateData.selectedStudents.length ?
                        _.map(stateData.selectedStudents, '_id') : [],
                    studentEmails: stateData.selectedStudents.length ?
                        _.map(stateData.selectedStudents.filter((dat: any) => { return !(stateData.prestineStudentIds.includes(dat._id)) }), 'email') : [],
                    selectedStudents: stateData.selectedStudents.length ?
                        stateData.selectedStudents : [],
                    sequenceData: sequenceClone.length ?
                        sequenceClone.find((x: any) => x.sequence === stateData.Sequence) : null,
                    selectedEvents: sequenceEventsClone.length ?
                        _.filter(sequenceEventsClone, function (o) { return !o["meetingDetails"]; }) : [],
            };
            
            fetch(API_URL + 'addCohort', this.httpService.httpInterceptor(httpMethods.POST,requestData))
                .then(async response => {
                    this.setState({ loader: false });
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    if (data && data.payload && !data.payload.toString().includes('Code Already Exists')) {
                        this.toasterService.notify('success', 'Cohort Code Successfully Generated');
                        if (stateData._id) {
                            this.gotToCohorts();
                        } else {
                            this.showPopUp(cohortCode);
                        }
                    } else if (data && data.payload.toString().includes('Code Already Exists')) {
                        this.toasterService.notify('error', 'Code Already Exists');
                    } else {
                        this.toasterService.notify('error', 'Failed to Add/Edit Cohort');
                    }
                })
                .catch(error => {
                    this.setState({ loader: false });
                    //   this.toasterService.notify('error', 'Login Failure');
                });
        } else {
            this.toasterService.notify('error', 'Please fill all the fields');
            this.setState({ loader: false });
        }
    }

    setDate(rowData: any) {
        return <span>{this.dateFormatService.convertData(rowData.dateOfEvent)}</span>;
    }
    setTime(rowData: any) {
        return <span>{this.dateFormatService.convertTime(rowData.startTimeOfEvent)}</span>;
    }

    tzConverter(timeOfEvent: any, tz: any) {
        const mom = moment(timeOfEvent);
        const currOffset = moment().utcOffset();
        const trgtOffset = moment.tz(tz).utcOffset();
        var diff = currOffset - trgtOffset;
        const newTime = mom.add(diff, 'minutes');
        return newTime.valueOf();
    }

    public revrseTzConverter(timeOfEvent: any, targetTz: any) {
        const mom = moment(timeOfEvent);
        const sourceOffset = moment().utcOffset();
        const trgtOffset = moment.tz(targetTz).utcOffset();
        var diff = trgtOffset - sourceOffset;
        const newTime = mom.add(diff, 'minutes');
        return new Date(newTime.valueOf());
    }

    render() {

        return AddCohortTemplate.call(this);
    }

}
