/* eslint-disable */
import { Component } from 'react';
import { API_URL } from '../../../common/constants/url';
import { DataService } from '../../../common/services/data-service';
import { ToasterService } from '../../../common/services/toastr-service';
import { ResetPasswordTemplate } from './reset-password-template';
//import notify from "../../../common/services/toastr-service";
export default class Login extends Component {
    public toasterService = new ToasterService();
    public dataService = new DataService();
    public error = null;//useState(false);
    public loading = false;//useState(false);
    public history: any;
    constructor(props: any) {
        super(props)
        window.sessionStorage.clear();
        this.onchanges = this.onchanges.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.resendOTP = this.resendOTP.bind(this);
        this.state = {
            loader: false,
            username: '',
            otp: '',
            password: '',
            confirmPassword: '',
            isValidOTP: false,
            _id: null,
            case:null
        }
    }
    componentDidMount() {
        if (location.pathname.replace('/reset-password/', '')) {
   
            const encodedString =  location.pathname.replace('/reset-password/', '').toString();
            const obj  = JSON.parse(atob(encodedString));
            this.setState({ username: obj.email ,case: obj.case});
            if(obj.case && obj.case === 2 ) {
                setTimeout(() => {
                    this.verifyOTP();
                }, 2000);
                    
            }
        }

    }
    public resetPassword() {
        const stateData: any = this.state;
        if (stateData.username && stateData.isValidOTP && stateData.password) {
            const requestData = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ _id: stateData._id, password: stateData.password })
            };
            fetch(API_URL + 'resetpassword', requestData)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const resdata = isJson && await response.json();
                    if (resdata && resdata.payload) {
                        this.toasterService.notify('success', 'Password changed Successfully.');
                        setTimeout(() => {
                           window.location.href = '/';
                        }, 5000);
                    } else {
                        this.toasterService.notify('error', 'Invalid Verification Code.');
                    }
                })
                .catch(error => {

                });
        }
    }
    public resendOTP() {
        this.dataService.verifyEmail(true, this.state);
    }

    public goToLogin() {
        window.location.href = "/";
    }

    public onchanges(event: any) {

        switch (event.target.name) {
            case 'otp': this.setState({ otp: event.target.value }); break;
            case 'password': this.setState({ password: event.target.value }); break;
            case 'confirmPassword': this.setState({ confirmPassword: event.target.value }); break;
        }
        if (event.target.name === 'otp' && event.target.value.toString().length === 4) {
            this.verifyOTP(event);
        }
    }

    public verifyOTP(event?:any){
        this.setState({ isValidOTP: false });
        const stateData: any = this.state;
        const requestData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: stateData.username, resetPasswordCode: event ?  event.target.value : null })
        };
        fetch(API_URL + 'verifyotp', requestData)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();
                if (resdata && resdata.payload) {

                    this.setState({ isValidOTP: true, _id: resdata.payload._id });
                } else if(resdata && resdata.error && (resdata.error).toString().includes('Expired Link')) {
                    this.toasterService.notify('error', 'Expired Link.');
                } else {
                    this.toasterService.notify('error', 'Invalid Verification Code.');
                }
            })
    }

    render() {
        return ResetPasswordTemplate.call(this);
    }
}



