


import React from 'react';
import SideNav from '../../../side-nav/side-nav';
import "./advisors-main.scss";

export const AdvisorsMainTemplate = function (this: any) {
    return (
        <div className="main-container d-flex">
            {  this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">Advisors</h2>
                            <div className="add-btn d-flex">
                                <span className="create-text">  Create New</span> <a className="add-box" onClick={this.addAdvisor}> <span className="add-icon"></span></a>
                            </div>
                        </div>
                        <div className="separator mb-2"></div>
                        <div className="tableformatCls">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Cohort</th>
                                        <th className="text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state && this.state.data && this.state.data.length ? this.state.data.map((listValue: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{listValue.firstName}</td>
                                                <td>{listValue.lastName}</td>
                                                <td>{listValue.email}</td>
                                                <td>{listValue?.cohortDetails && listValue?.cohortDetails?.cohortName &&  
                                                new Date(listValue?.cohortDetails?.cohortEndDate) >= new Date()  ?
                                                  listValue.cohortDetails?.cohortName : '-'}</td>
                                                <td>
                                                    <ul className="action-icon d-flex justify-content-center">
                                                        <li><a onClick={() => this.editAdvisor(listValue)} className="edit-icon"></a></li>
                                                        <li><a className="delete-icon" onClick={() => this.deleteAdvisor(listValue._id, index)}></a></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        );
                                    }) : <tr>
                                        <td className="text-center" colSpan={5}>No Data Available</td>
                                    </tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};


