import React from 'react';
import SideNav from '../../../side-nav/side-nav';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RoleEnum } from '../../../../../common/constants/role-enum';
import { Dropdown } from 'primereact/dropdown';
import "./add-cohort.scss";
import { Calendar } from 'primereact/calendar';
export const AddCohortTemplate = function (this: any) {
    return (


        <div className="main-container d-flex">
            {this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title"> {this.editData?.editCohort ? 'Edit Cohort' : 'Add Cohort'}</h2>
                            <a className="back-btn" onClick={this.gotToCohorts}><span className="back-icon"></span>Back</a>
                        </div>
                        {this.state.showPopup ?
                            <div className="modal-FormatCls medium-modal-FormatCls">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Cohort Code</h5>
                                            <span className="close" onClick={() => this.gotToCohorts()}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div>
                                        <div className="modal-body">
                                            <div className="Cohorts-link-box">
                                                <h6>Please share this code with the students to join the cohort.</h6>
                                                {/* <p>Code : {this.state.link}</p> */}
                                                <div className="d-flex align-items-center">
                                                    <div className={this.state.isCopied ? 'success-bg inner-box' : 'inner-box'}>
                                                        <span className="d-block mr-2">Code : </span>
                                                        <a className="d-block">{this.state.link}
                                                        </a>
                                                    </div>
                                                    <div className="copy-icon-box">
                                                        <a className="copy-icon" onClick={() => this.copyText(this.state.link)}></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="box">
                                    <span className="close-icon" onClick={() => this.gotToCohorts()}>x</span>
                                    <div className={(this.state.isCopied) ? 'green-bg inner-box' : 'inner-box'}>
                                        <h6><u>Please share this link with the students to join the cohort.</u></h6>
                                        <span>Code : {this.state.link}</span>
                                        <span className="d-flex">  Link : <a className="d-flex">{this.PROD_URL + btoa(this.state.link)}
                                        <a className="copy-icon" onClick={() => this.copyText(this.PROD_URL + btoa(this.state.link))}></a></a></span>
                                    </div>
                                </div> */}
                            </div> : ''}
                        <div className="separator"></div>
                        <form>
                            <div className="row">
                            <div className="col-md-6">
                                    <div className="inputgroup">
                                        <label className="label" htmlFor="cohort"> Cohort Name </label>
                                        <input type="text" name="cohort" id="cohort" placeholder="Enter Cohort Name"
                                            value={this.state.CohortName} onChange={this.onchanges}
                                            className="form-control" required />
                                        {this.state.errormessage}
                                    </div>
                                </div>
                                <div className="col-md-6"> </div>
                                {this.userInfo && this.userInfo.role === RoleEnum.Admin ?
                                    <div className="col-md-6">
                                        <div className="Dropdown-formatCls">
                                            <label className="label">Advisor</label>
                                            <Dropdown value={this.state.Advisor ? this.state.Advisor : ''}
                                                options={this.state.advisorOptions}
                                                className="p-dropdown" name="advisor"
                                                onChange={this.onchanges} placeholder="Select Advisor" />
                                        </div>
                                    </div> : ''
                                    }
                               
                                <div className="col-md-6">
                                    <div className="Dropdown-formatCls">
                                        <label className="label">Sequence</label>
                                        <Dropdown value={this.state.Sequence ? this.state.Sequence : ''}
                                            options={this.state.sequenceOptions}
                                            className="p-dropdown" name="sequence"
                                            onChange={this.onchanges} placeholder="Select Sequence" />
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                <div className="col-md-6">
                           
                                    <div className="calendar-formatCls">
                                        <label className="label"> Start Date </label>
                                        <Calendar placeholder="MM/DD/YY" value={this.state.StartDate ? new Date(this.state.StartDate) : new Date()}
                                            disabled={!this.state.Sequence}
                                            minDate={new Date()} maxDate={this.state.EndDate ? new Date(this.state.EndDate) : new Date()}
                                            name="dateOfEvent" onChange={(date) => this.onchanges(date, 'date')}></Calendar>

                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="DatePicker-formatCls">
                                        <label className="label"> End Date </label>

                                        <DatePicker className="react-datepicker" placeholderText="MM/DD/YY"
                                            minDate={new Date(this.state.StartDate)} disabled
                                            selected={this.state.EndDate} onChange={(date) => this.onchanges(date, 'date')} />
                                    </div>
                                </div>
                            </div>
                            <div className="orange-separator mt-4 mb-4"></div>
                            <div className="box-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="table-title">Selected Students for the Cohort</h2>

                                            <div className="add-btn d-flex">
                                                <span className="create-text">Select Students</span><a className="add-box" onClick={e => this.setState({ showStudents: true })}> <span className="add-icon"></span></a>
                                            </div>
                                        </div>
                                        <div className="separator"></div>
                                        <div className="prime-tableformatCls">
                                            <DataTable value={this.state.selectedStudents} scrollable
                                                dataKey="_id"
                                                scrollHeight="160px">
                                                <Column field="firstName" header="First Name" sortable></Column>
                                                <Column field="lastName" header="Last Name" sortable></Column>
                                                <Column field="email" header="Email" sortable></Column>
                                            </DataTable>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="orange-separator mt-4 mb-4"></div>
                            <div className="box-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className="table-title">Selected Events to send the Meeting Invite</h2>

                                        </div>
                                        <div className="separator"></div>
                                        <div className="prime-tableformatCls">
                                            <DataTable value={this.state.eventList} scrollable dataKey="_id" scrollHeight="400px">

                                                <Column field="week" header="Week" sortable></Column>
                                                <Column field="session" header="Session" sortable></Column>
                                                <Column field="dateOfEvent" header="Event Date" body={this.setDate} sortable></Column>
                                                <Column field="startTimeOfEvent" header="Event Time" body={this.setTime} sortable></Column>

                                            </DataTable>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator mt-4 mb-4"></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <button className="btn btn-primary mr-3" type="button"
                                            onClick={this.submitForm}>Save</button>
                                        <button className="btn btn-primary"
                                            onClick={this.gotToCohorts}
                                        >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>




                        {this.state.showStudents ?
                            <div className="modal-FormatCls">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Select Students for the Cohort</h5>
                                            <span className="close" onClick={e => this.setState({ showStudents: false })}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div>
                                        <div className="modal-body">
                                            <div className="prime-tableformatCls">
                                                <DataTable value={this.state.studentsData} scrollable
                                                    selection={this.state.selectedStudents}
                                                    onSelectionChange={e => this.setState({ selectedStudents: e.value })}
                                                    dataKey="_id"
                                                    scrollHeight="400px">
                                                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>

                                                    <Column field="firstName" header="First Name" sortable></Column>
                                                    <Column field="lastName" header="Last Name" sortable></Column>
                                                    <Column field="email" header="Email" sortable></Column>
                                                </DataTable>
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                            <div className="separator"></div>
                                            <div className="">
                                                <button className="btn btn-primary mr-3" onClick={e => this.setState({ showStudents: false })}>Save</button>
                                                <button className="btn btn-primary" onClick={e => this.setState({ showStudents: false })}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div> : ''}


                    </div>
                </div>
            </div>
        </div>

    )
};
