
/* eslint-disable */
export class DateFormatService {

    constructor() { }

    public convertData(str: any) {
        const date = new Date(str);
        const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join('-');
    }

    public convertTime(str:any) {
        const date = new Date(str);
        const hours = ('0' + (date.getHours())).slice(-2);
        const minutes = ('0' + (date.getMinutes())).slice(-2);
        return [hours,minutes].join(':')
    }

}