/* eslint-disable */
import React from 'react';
import "./login.scss";
import MicrosoftLogin from "react-microsoft-login";
export const loginTemplate = function (this: any) {
  return (

    <div className="login-main">
      {  this.state.loader ?
        <div className="loader-wrapper">
          <div className="loader">Loading...</div>
        </div> : ''
      }
      <div className="login-wrapper d-flex justify-content-center align-items-center h-100">
        <div className="login-info">
          <div className="row">
            <div className="col-md-6">
              <div className="login-logo-wrapper h-100 d-flex justify-content-center align-items-center">
                <div className="w-100">
                  <div>
                    <a className="logo"></a>
                    <a className="logo-title">ProjectYou</a>
                  </div>
                  <div className="tag-line-box">
                    <p className="tag-line">Gain a competitive advantage.</p>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-info-wrapper">
                <div>
                  <h3 className="login-title">Login</h3>
                </div>

                {this.state.showPopup ? <div className="popup-box">
                  <div className="sm-box">
                    <span className="sm-close-icon" onClick={this.closePopup}>x</span>
                    <div className="inner-box">
                      <div className="inputgroup">

                        <input type="text" value={this.state.cohortCode} onChange={this.onchanges}
                          name="cohortCode" className="form-control" id="cohortCode"
                          placeholder="Enter Cohort Code"></input>
                      </div>

                      <div>
                        <button type="button" className="btn btn-primary" onClick={this.verifyCode}>Verify Code</button>
                      </div>
                    </div>
                  </div>
                </div> : ''}
                <div className="inputgroup">
                  <label htmlFor="formGroupExampleInput" className="label">Email</label>
                  <input type="text" value={this.state.username} onChange={this.onchanges}
                    name="username"
                    className="form-control" id="formGroupExampleInput" placeholder="Enter Email Address"></input>
                </div>

                <div className="inputgroup">
                  <label htmlFor="formGroupExampleInput2" className="label">Password</label>
                  <input type="password" className="form-control"
                    value={this.state.password} onChange={this.onchanges}
                    name="password"
                    id="formGroupExampleInput2" placeholder="Enter Password"></input>
                  <div className="d-flex justify-content-end">
                    <a className="forgot-link" onClick={this.redirectToForgotPassword}>Forgot Password</a>
                  </div>
                </div>
              </div>
              {/* {this.error && <><small style={{ color: 'red' }}>{this.error}</small><br /></>} */}
              <div className="text-center login-btn pt-2">
                <input type="button" style={{ width: '12rem', marginBottom: '3px' }} value={this.loading ? 'Loading...' : 'Login'}
                  onClick={this.handleLogin} disabled={this.loading} className="btn btn-primary"></input>
                <br />
                <span style={{color:'grey'}}>-------- or -------</span>
                <br />
                <div className="d-flex social-login mb-4">
                <div className="google-btn">
                  <div className="google-icon-wrapper">
                    <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                  </div>
                  <a className="btn-text" onClick={this.googleLogin}>Sign in</a>
                </div>
                <span className="break">|</span>
                <div className="Microsoft-btn">
                <MicrosoftLogin clientId={'d794f772-7bb9-4e1c-a7fe-f5d5cda99902'}
                 authCallback={this.microsoftLoginHandler} className="temp"
                 redirectUri={this.PROD_URL}
                 withUserData= {true} tenantUrl={'https://login.microsoftonline.com/common'}
                  buttonTheme={'light_short'} />
                
                </div>
                </div>
              </div>
              {/* <div className="link-box text-center">
                <a className="link-title">Don&apos;t have an Account ? Sign Up Today.</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
