import React from 'react';
import SideNav from '../side-nav/side-nav';
import { RoleEnum } from '../../../common/constants/role-enum';
import "./dashboard.scss";
import { Calendar } from 'primereact/calendar';
import TimezoneSelect from 'react-timezone-select';
import { ButtonGroup, Button } from 'react-bootstrap';
// const momenttz = require('moment-timezone');

export const DashboardTemplate = function (this: any) {
    return ((this.loggedIn && this.loggedIn.userInfo && this.loggedIn.userInfo.role &&
        this.loggedIn.userInfo.role === RoleEnum.Admin) ? (

        <div className="main-container d-flex">
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper Advisor-box h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">Dashboard</h2>
                        </div>
                        <div className="separator"></div>

                        <div>
                            <p className="calendar-info-title">ProjectYou would like to access your calendar. Please select from :</p>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="calendar-list">
                                        <ul className="nav flex-column">
                                            <li className="nav-item">
                                                <button type="button" className="nav-link" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                                                    Outlook
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button type="button" onClick={() => this.setState({ showCalendar: true })} className="nav-link" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                    Google calendar
                                                </button>
                                            </li>
                                          
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade action-modal" id="staticBackdrop" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Zoom Integration</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div>
                                            <p className="action-title">Part of what makes us work so well is our zoom integration. Do you grant access ?</p>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Agree</button>
                                        <button type="button" className="btn">Disagree</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        :
        (



            <div className="main-container d-flex">
               <SideNav /> 
                <div className="right-container-panel d-flex align-items-stretch">
                    <div className="container-fluid w-100">
                        <div className="box-wrapper h-100">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="sub-title">Welcome, {this.loggedIn?.userInfo?.fullName}!</h2>

                            </div>
                            <div className="separator"></div>
                            <div>
                               {   this.loggedIn.userInfo.role === RoleEnum.Advisor ? 
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <div className="box-wrapper">
                                            <div className="d-flex justify-content-between align-items-center">
                                            <a className="linktitle">My Calendar</a>
                                            <a className="view-icon view-box" onClick={() => this.setState({"showCalendar":true})}></a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="col-md-6">
                                    <div className="box-wrapper">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <a className="linktitle">My Cohorts</a>
                                        <a className="view-icon view-box" onClick={() => this.setState({"showCohorts":true})}></a>
                                        </div>
                                    </div>
                                        
                                    </div>
                                </div> : ''}
                                    {   this.loggedIn.userInfo.role === RoleEnum.Student ? 
                                    <div className="box-wrapper">  
                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="inputgroup">
                                                <label className="label" htmlFor="cohort"> Cohort Code </label>
                                                <input type="text" name="cohort" 
                                                    value={this.loggedIn?.userInfo?.cohortCode} 
                                                    className="form-control" disabled />
                                                
                                            </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="inputgroup">
                                                <label className="label" htmlFor="CohortName">Cohort </label>
                                                <input type="text" name="cohort" id="CohortName"
                                                    value={this.state.cohortData.length ?
                                                        this.state.cohortData[0].cohortName : ''}  
                                                    className="form-control" disabled />
                                            
                                            </div>
                                                </div>
                                            </div> 
                                            <div className="row">
                                            <div className="col-md-6">
                                            <div className="inputgroup">
                                                <label className="label" htmlFor="advisorEmail"> Advisor Email</label>
                                                <input type="text" name="advisorEmail" 
                                                    value={this.state.cohortData.length ? 
                                                        this.state.cohortData[0].advisorEmail : ''} 
                                                    className="form-control" disabled />
                                            
                                            </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="inputgroup">
                                            <label className="label" htmlFor="advisorName"> Advisor  </label>
                                            <input type="text" name="advisorName" id="advisorName"
                                                value={this.state.cohortData.length ? 
                                                    this.state.cohortData[0].advisorName : ''} 
                                                className="form-control" disabled />
                                        
                                        </div>
                                            </div>
                                        </div>
                                    </div>: '' }
                                    <div className="orange-separator"></div>
                                <div className="row">
                                        <div className="col-md-6 d-flex align-items-stretch">
                                            <div className="box-wrapper w-100 h-100">
                                            <div className="">
                                                 <h4 className="table-title">Upcoming Cohort Sessions</h4>
                                            </div>
                                            <div className="separator"></div>
                                            
                                            <div className="tableformatCls">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Session</th>
                                                            <th scope="col">Start Date</th>
                                                            <th className="text-center" scope="col">Details</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state && this.state.data.length ?
                                                            this.state.data.filter((x: any) => x.sessionType === 'Cohort Session').map((listValue: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{listValue.session}</td>
                                                                        <td>{this.dateFormatService.convertData(listValue.dateOfEvent)}</td>

                                                                        <td >
                                                                            <ul className="action-icon d-flex justify-content-center">
                                                                            <a className="view-icon view-box" onClick={() => this.showPopUp({ ...listValue },'showPopUp')}></a>
                                                                            </ul>
                                                                         </td>
                                                                    </tr>
                                                                );
                                                            }) : ''
                                                        }
                                                        {
                                                            this.state && !this.state.data?.length ?
                                                            <tr>
                                                            <td className="text-center" colSpan={5}>No Data Available</td>
                                                        </tr>: ''
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6 d-flex align-items-stretch">
                                        <div className="box-wrapper w-100 h-100">
                                        <div className="">
                                            <h4 className="table-title">Upcoming 1:1 Sessions</h4>
                                        </div>
                                            <div className="separator"></div>

                                            <div className="tableformatCls">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Session</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">Schedule</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state && this.state.data.length ?
                                                            this.state.data.filter((x: any) => x.sessionType !== 'Cohort Session').map((listValue: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{listValue.session}</td>
                                                                        <td>{this.dateFormatService.convertData(listValue.dateOfEvent)}</td>

                                                                        <td>
                                                                            {this.loggedIn?.userInfo?.role === RoleEnum.Student?
                                                                             <a className="action-title"  onClick={() => this.showPopUp({ ...listValue },'showCalendar')}>Link</a> :
                                                                             <a className="action-title"  onClick={() => window.location.href = '/requests'}>Link</a>}
                                                                            </td>

                                                                    </tr>
                                                                );
                                                            }) : ''
                                                        }
                                                        {
                                                            this.state && !this.state.data?.length ?
                                                            <tr>
                                                            <td className="text-center" colSpan={5}>No Data Available</td>
                                                        </tr>: ''
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </div>
                                        
                                </div>
                            </div>
                        </div>
  
                        {this.state.showCalendar ? 
                        <div className="modal-FormatCls large-modal-FormatCls">
                            <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{ this.loggedIn.userInfo.role === RoleEnum.Student ?  'Schedule' : 'My Calendar'}</h5>
                                            <span className="close" onClick={e => this.setState({ showCalendar: false })}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div> 
        
                                        <div className="modal-body">
                                            <div className="no-data-info">
                                            <div dangerouslySetInnerHTML={ this.iframe() } />
                                            {/* {this.state.advisorData && this.state.advisorData.iFrame ?  JSON.parse() : 'No Data Available'}  */}
                                            </div>
                                            <div className="orange-separator mt-4 mb-4"></div>
                                       {  this.loggedIn.userInfo.role === RoleEnum.Student ?     <div className="row">
                                                <div className="col-md-4">
                                                    <div className="calendar-formatCls">
                                                        <label className="label">Day of Session</label>
                                                        
                                                        <Calendar placeholder="Day of Session"  value={this.state.dateOfEvent ? new Date(this.state.dateOfEvent) : undefined}
                                                            minDate={this.state.sessionDetails &&  this.state.sessionDetails.dateOfEvent ? 
                                                                new Date(this.state.sessionDetails.dateOfEvent ):new Date()}
                                                                maxDate={this.state.sessionDetails &&  this.state.sessionDetails.endTimeOfEvent ? 
                                                                    new Date(this.state.sessionDetails.endTimeOfEvent ):new Date()}
                                                                    disabled={this.state.sessionDetails.endTimeOfEvent && 
                                                                        new Date(this.state.sessionDetails.endTimeOfEvent) < new Date()}
                                                            name="dateOfEvent" onChange={(date) => this.onchanges(date, 'date')}></Calendar>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                                <div className="inputgroup">
                                                                    <label className="label">Time zone</label>
                                                                    <TimezoneSelect
                                                                        value={this.state.tz ? this.state.tz : ''} name="tz" 
                                                                        onChange={(item: any) => this.onchanges(item.value, 'tz')} />
                                                                </div>
                                                            </div>
                                                     
                                                <div className="col-md-4">
                                                    <div className="calendar-formatCls">
                                                        <label className="label">Start Time { <span className="timeError">{(this.state.startTimeOfEvent && this.state.isStartDateInvalid) &&
                                                                        'Please Enter Time Between 8 AM to 6 PM'}</span> }</label>

                                                    
                                                        <Calendar timeOnly showTime hourFormat="12" placeholder="hh:mm"
                                                          disabled={(this.state.sessionDetails.endTimeOfEvent && 
                                                            new Date(this.state.sessionDetails.endTimeOfEvent) < new Date()) || !this.state.dateOfEvent}
                                                            value={this.state.startTimeOfEvent} 
                                                            onChange={(time) => this.onchanges(time,'startTime')}></Calendar>
                                                               {/* { <p className="datetext">{this.state.startTimeOfEvent && this.state.tz?
                                                                        'According to the Timezone Session Begins on ' 
                                                                        + momenttz(new Date(this.state.startTimeOfEvent))
                                                                        .tz(this.state.tz).format("dddd, MMMM Do YYYY, h:mm a") : ''}</p> } */}
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="calendar-formatCls">
                                                        <label className="label">End Time { <span className="timeError">{(this.state.endTimeOfEvent && this.state.isEndDateInvalid) &&
                                                                        'Please Enter Time Between 8 AM to 6 PM'}</span> }</label>
                                                        <Calendar timeOnly showTime hourFormat="12" placeholder="hh:mm"
                                                             disabled={(this.state.sessionDetails.endTimeOfEvent && 
                                                                new Date(this.state.sessionDetails.endTimeOfEvent) < new Date())
                                                                 || !this.state.dateOfEvent}
                                                            value={this.state.endTimeOfEvent} 
                                                            onChange={(time) => this.onchanges(time, 'endTime')}></Calendar>
                                                              {/* { <p className="datetext">{this.state.endTimeOfEvent && this.state.tz?
                                                                        'According to the Timezone Session Begins on ' 
                                                                        + momenttz(new Date(this.state.endTimeOfEvent))
                                                                        .tz(this.state.tz).format("dddd, MMMM Do YYYY, h:mm a") : ''}</p> } */}
                                                           
                                                    
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        </div>
                                       { this.loggedIn.userInfo.role === RoleEnum.Student ?  <div className="modal-footer justify-content-center">
                                        <div className="separator"></div>
                                               <div>
                                               <button className="btn btn-primary mr-3" disabled={!this.state.dateOfEvent || !this.state.startTimeOfEvent || !this.state.endTimeOfEvent}  onClick={this.requestSession}>Save</button> 
                                                <button className="btn btn-primary" onClick={e => this.setState({ showCalendar: false })}>Back</button>
                                               </div>
                                        </div> : '' }
                                    </div>
                                </div>
                        </div> : ''}

                        {this.state && this.state.showPopUp ? 
                        <div className="modal-FormatCls">
                            <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Session Details</h5>
                                            <span className="close" onClick={e => this.setState({ showPopUp: false, sessionDetails: {} })}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Sequence </label>
                                                        <div className="form-control info-form">{this.state.sessionDetails?.sequence}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Session </label>
                                                        <div className="form-control info-form">{this.state.sessionDetails?.session}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Session Type</label>
                                                        <div className="form-control info-form">{this.state.sessionDetails?.sessionType}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Week#</label>
                                                        <div className="form-control info-form">{this.state.sessionDetails?.week}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Start Date</label>
                                                        <div className="form-control info-form">{this.dateFormatService.convertData(this.state.sessionDetails?.dateOfEvent)}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Start Time</label>
                                                        <div className="form-control info-form">{this.dateFormatService.convertTime(this.state.sessionDetails?.startTimeOfEvent)}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">End Time</label>
                                                        <div className="form-control info-form">{this.dateFormatService.convertTime(this.state.sessionDetails?.endTimeOfEvent)}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="inputgroup">
                                                        <label className="label">Notes</label>
                                                        <div className="form-control info-form">{this.state.sessionDetails?.notes}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                             <div className="separator mt-0"></div>
                                               <div>
                                               <button className="btn btn-primary" onClick={e => this.setState({ showPopUp: false, sessionDetails: {} })}>Back</button>
                                               </div>
                                        </div>
                                    </div>
                                </div>
                        </div> : ''}




                        {this.state.showCohorts ? 
                        <div className="modal-FormatCls large-modal-FormatCls">
                            <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">My Cohorts</h5>
                                            <span className="close" onClick={e => this.setState({ showCohorts: false })}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div> 
        
                                        <div className="modal-body">
                                        <div className="tab-header">
                            <ButtonGroup aria-label="Basic example">
                                    <Button variant={(this.state.isActive) ? 'btn btn-btn' : 'btn'} onClick={e => this.setState({isActive: true, cohortData:
                                        this.state.cohortDataClone.filter((x:any)=> new Date(x.endDate) >= new Date())})}>Active</Button>
                                    <Button variant={(this.state.isActive) ? 'btn' : 'btn btn-btn active-btn'} onClick={e => this.setState({isActive:false, cohortData:
                                        this.state.cohortDataClone.filter((x:any)=> new Date(x.endDate) < new Date())})}>Inactive</Button>

                            </ButtonGroup>
                            </div>
                                        <div className="tableformatCls">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Cohort</th>
                                                            <th scope="col">Code</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state && this.state.cohortData.length ?
                                                            this.state.cohortData.map((listValue: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{listValue.cohortName}</td>
                                                                        <td>{listValue.cohortCode}</td>
                                                                        <td>{this.dateFormatService.convertData(listValue.startDate)}</td>
                                                                        <td>{this.dateFormatService.convertData(listValue.endDate)}</td>
                                                                    </tr>
                                                                );
                                                            }) : <tr>
                                                            <td className="text-center" colSpan={5}>No Data Available</td>
                                                        </tr>
                                                        }
                                                        

                                                    </tbody>
                                                </table>
                                            </div>
                                             </div>
                                </div>
                                </div>
                        </div> : ''}

                    </div>
                </div>
            </div>

        )
    )
}
