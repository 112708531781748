/* eslint-disable */
import React, { useState, Component } from 'react';
import { StudentsMainTemplate } from './students-main-template';
import history from '../../../../../config/router/history';
import { API_URL } from '../../../../../common/constants/url';
import _ from 'lodash';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { confirmAlert } from 'react-confirm-alert';
import { httpMethods } from '../../../../../common/constants/httpEnum';
import { HttpService } from '../../../../../common/services/http-service';
export default class Students extends Component {
  public loggedIn = window.sessionStorage.getItem("loginUser");
  public toasterService = new ToasterService();
  public httpService = new HttpService();
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      data: [],
    }
    let copyloggedIn: any = this.loggedIn;
    if (_.isString(copyloggedIn)) {
      copyloggedIn = JSON.parse(copyloggedIn);
    }
    copyloggedIn["editData"]["editStudent"] = null;
    window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
    this.deleteStudent = this.deleteStudent.bind(this);
  }


  componentDidMount() {
    const stateData: any = this.state;
    if (!stateData.data.length) {
      this.getStudentsData();
    }
  }

  public editStudent(rowData: any) {
    if (rowData) {
      let copyloggedIn: any = this.loggedIn;
      if (_.isString(copyloggedIn)) {
        copyloggedIn = JSON.parse(copyloggedIn);
      }
      copyloggedIn["editData"]["editStudent"] = { ...rowData };
      window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
      history.push('/add-student');
      window.location.reload();
    }
  }

  public addStudent() {
    history.push('/add-student');
    window.location.reload();
  }

  public deleteStudent(id: number, rowIndex: number,rowData?:any) {
    if(rowData.cohortDetails &&
       new Date() < new Date(rowData.cohortDetails.cohortEndDate)  && 
       new Date() > new Date(rowData.cohortDetails.cohortStartDate)) {
        this.toasterService.notify('error', 'Student is a part of an Active Cohort');
        return;
       }else{
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='conformation-popup'>
                <h1>Are you sure?</h1>
                <p>You want to delete this item?</p>
                <button className="confirm-btn" onClick={onClose}>No</button>
                <button className="confirm-btn"
                  onClick={() => {
                    this.setState({ loader: true });
                    const stateData: any = this.state;
                    if (stateData.data.length) {
    
                      fetch(API_URL + 'deletestudent',
                        this.httpService.httpInterceptor(httpMethods.POST,
                          { _id: id ? id : null }))
                        .then(async response => {
                          this.setState({ loader: false });
                          if (!response.ok && !response.headers) {
                            throw Error(response.statusText);
                          }
                          const isJson = response.headers.get('content-type')?.includes('application/json');
                          const data = isJson && await response.json();
                          if (data && data.payload) {
                            this.toasterService.notify('success', 'Student Successfully Deleted');
                            stateData.data.splice(rowIndex, 1);
                            this.setState({ data: [...stateData.data] });
                            onClose();
                          } else {
                            this.toasterService.notify('error', 'Failed to Delete Student');
                            onClose();
                          }
                        })
                        .catch(error => {
                          this.setState({ loader: false });
                          //   this.toasterService.notify('error', 'Login Failure');
                        });
                    } else {
                      this.setState({ loader: false });
                    }
                  }}>
                  Yes, Delete it!
                </button>
              </div>
            );
          }
        });
       }
   
  }

  public getStudentsData() {

    fetch(API_URL + 'getStudentsData',
      this.httpService.httpInterceptor(httpMethods.GET))
      .then(async response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();

        if (resdata && resdata.payload) {
          this.setState({ data: [...resdata.payload] });
        }
      })
      .catch(error => {
        //   notify('error', ' Failure');
      });
  }


  render() {
    return StudentsMainTemplate.call(this);
  }
}

