/* eslint-disable */
import React, { Component, useState } from 'react';
import { logout, isLogin } from '../../../../../utils';
import { AdvisorsMainTemplate } from './advisors-main-template';
import { API_URL } from '../../../../../common/constants/url';
import { toast } from 'react-toastify';
import history from '../../../../../config/router/history';
import _ from 'lodash';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { confirmAlert } from 'react-confirm-alert';
import { httpMethods } from '../../../../../common/constants/httpEnum';
import { HttpService } from '../../../../../common/services/http-service';

export default class AdvisorsMain extends Component {
  public toasterService = new ToasterService();
  public loggedIn = window.sessionStorage.getItem("loginUser");
  public httpService = new HttpService();
  public name = this.loggedIn ? JSON.parse(this.loggedIn).userInfo.email : '';
  constructor(props: any) {
    super(props);
    this.state = {
      isLogin: isLogin(),
      data: [],
      loader:false
    }
    this.deleteAdvisor = this.deleteAdvisor.bind(this);
    let copyloggedIn: any = this.loggedIn;
    if (_.isString(copyloggedIn)) {
      copyloggedIn = JSON.parse(copyloggedIn);
    }
    copyloggedIn["editData"]["editAdvisor"] = null;
    window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
  }


  componentDidMount() {
    const stateData: any = this.state;
    if (!stateData.data.length) {
      this.getAdvisorsData();
    }
  }

  public deleteAdvisor(id: number, rowIndex: number) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='conformation-popup'>
            <h1>Are you sure?</h1>
            <p>You want to delete this item?</p>
            <button className="confirm-btn" onClick={onClose}>No</button>
            <button className="confirm-btn"
              onClick={() => {
                this.setState({loader:true});
                const stateData: any = this.state;
                if (stateData.data.length) {
                  fetch(API_URL + 'deleteadvisor', 
                  this.httpService.httpInterceptor(httpMethods.POST,{
                    _id: id ? id : null
                  }))
                    .then(async response => {
                      this.setState({loader:false});
                      if (!response.ok && !response.headers) {
                        throw Error(response.statusText);
                      }
                      const isJson = response.headers.get('content-type')?.includes('application/json');
                      const data = isJson && await response.json();
                      if (data && data.payload) {
                        this.toasterService.notify('success', 'Advisor Successfully Deleted');
                        stateData.data.splice(rowIndex, 1);
                        onClose();
                        this.setState({ data: [...stateData.data] });
                      } else {
                        this.toasterService.notify('error', 'Failed to Delete Advisor');
                        onClose();
                      }
                    })
                    .catch(error => {
                      this.setState({loader:false});
                      //   this.toasterService.notify('error', 'Login Failure');
                    });
            
                }else{
                  this.setState({loader:false});
                }
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  }



  public addAdvisor() {
    history.push('/add-advisor');
    window.location.reload();
  }

  public editAdvisor(rowData: any) {
    if (rowData) {
      let copyloggedIn: any = this.loggedIn;
      if (_.isString(copyloggedIn)) {
        copyloggedIn = JSON.parse(copyloggedIn);
      }
      copyloggedIn["editData"]["editAdvisor"] = { ...rowData };
      window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
      history.push('/add-advisor');
      window.location.reload();
    }
  }

  public getAdvisorsData() {
    this.setState({loader:true});
    fetch(API_URL + 'getAdvisorsData', this.httpService.httpInterceptor(httpMethods.GET))
      .then(async response => {
        this.setState({loader:false});
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();

        if (resdata && resdata.payload) {
          this.setState({ data: [...resdata.payload] });
        }
      })
      .catch(error => {
        this.setState({loader:false});
        //   notify('error', 'Login Failure');
      });
  }


  render() {
    return AdvisorsMainTemplate.call(this);
  }
}






