/* eslint-disable */
import React, { Component, lazy, useState } from 'react';
import { loginTemplate } from './login.template';
import { login, signInWithGoogle } from '../../../utils';
import { Link, Route, Router, useParams, useHistory } from 'react-router-dom';
import { API_URL,PROD_URL } from '../../../common/constants/url';
import { toast } from 'react-toastify';
import history from '../../../config/router/history';
import { ToasterService } from '../../../common/services/toastr-service';
import { RoleEnum } from '../../../common/constants/role-enum';

//import notify from "../../../common/services/toastr-service";
export default class Login extends Component {
    public toasterService = new ToasterService();
    public error = null;//useState(false);
    public loading = false;//useState(false);
    public history: any;
    constructor(props: any) {
        super(props)
        window.sessionStorage.clear();
        this.handleLogin = this.handleLogin.bind(this);
        this.googleLogin = this.googleLogin.bind(this);
        this.signup = this.signup.bind(this);
        this.verifyCode = this.verifyCode.bind(this);
        this.onchanges = this.onchanges.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.microsoftLoginHandler = this.microsoftLoginHandler.bind(this);
        this.state = {
            loader: false,
            userCode: '',
            showPopup: false,
            setPopup: false,
            cohortCode: '',
            username: '',
            password: '',
            userEmail: '',
            name: '',
        }
    }

    public microsoftLoginHandler = (err: any, data: any, msal: any) => {
        if (!err && data && data.account) {
            const userData = data.account;
            const stateData: any = this.state;
            if (userData && userData.userName && userData.name && !stateData.showPopup) {
                this.signup({ email: userData.userName, name: userData.name });
            } else if (!stateData.showPopup) {
                this.toasterService.notify('error', 'Login Failure');
            }
        }
    };
    public onchanges(event: any) {
        switch (event.target.name) {
            case 'username': this.setState({ username: event.target.value }); break;
            case 'password': this.setState({ password: event.target.value }); break;
            case 'cohortCode': this.setState({ cohortCode: event.target.value }); break;
        }
    }

    public closePopup() {
        this.setState({ showPopup: false });
        history.push('/');
        window.location.reload();
    }

    public redirectToForgotPassword(){
    window.location.href = '/forgot-password'
}
    public async handleLogin() {
    this.setState({ userEmail: '', name: '', loader: true });
    let stateData: any = this.state;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: stateData.username, password: stateData.password })
    };
    fetch(API_URL + 'login', requestOptions)
        .then(async response => {
            this.setState({ loader: false });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            data["navData"] = {
                advisorsLink: data.userInfo.role  === RoleEnum.Admin ? true : false ,
                cohortsLink: false,
                sequncesLink: false,
                studentsLink: false,
                settingLink: false,
                iscollapsed: false,
                requestsLink:false,
                supportLink:false,
                dashboardLink:data.userInfo.role  !== RoleEnum.Admin ? true : false
            }
            data["editData"] = {
                editCohort: null,
                editAdvisor: null,
                editStudent: null,
                editSequence: null
            }
            this.setState({ loader: false });
            window.sessionStorage.setItem("loginUser", JSON.stringify(data));
            this.toasterService.notify('success', 'Login Successful');
            this.redirectToDashboard(data);
        })
        .catch(error => {
            this.setState({ loader: false });
            // console.error('There was an error!', error);
            this.toasterService.notify('error', 'Invalid username or password');
        });
}

    public async googleLogin() {
    const reqObj = await signInWithGoogle();
    if (reqObj && reqObj.additionalUserInfo && reqObj.additionalUserInfo.profile) {
        this.signup(reqObj.additionalUserInfo.profile);
    } else {
        this.toasterService.notify('error', 'Login Failure');
    }
}

    public verifyCode(): any {
    this.setState({ loader: true });
    let stateData: any = this.state;
    if (stateData.cohortCode && stateData.cohortCode && /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{4,}$/g.test(stateData.cohortCode) &&
        (stateData.cohortCode).replace(/[^a-z]/gi, '').length === 2 && /^(\D*\d){2}\D*$/.test(stateData.cohortCode)) {

        const requestData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ cohortCode: stateData.cohortCode, email: stateData.userEmail, fullName: stateData.name })
        };
        fetch(API_URL + 'verifyCohortCode', requestData)
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (data && !data.error) {

                    data["navData"] = {
                        advisorsLink: data.userInfo.role  === RoleEnum.Admin ? true : false ,
                        cohortsLink: false,
                        sequncesLink: false,
                        studentsLink: false,
                        settingLink: false,
                        iscollapsed: false,
                        requestsLink:false,
                        supportLink:false,
                        dashboardLink:data.userInfo.role  !== RoleEnum.Admin ? true : false
                    }
                    data["editData"] = {
                        editCohort: null,
                        editAdvisor: null,
                        editStudent: null,
                        editSequence: null
                    }
                    window.sessionStorage.setItem("loginUser", JSON.stringify(data));
                    this.toasterService.notify('success', 'Successfully Registered');
                    this.redirectToDashboard(data);
                } else {
                    this.toasterService.notify('error', 'Code Does not exists');
                }
            })
            .catch(error => {
                this.setState({ loader: false });
                this.toasterService.notify('error', 'Code Does not exists');
            });
    } else {
        this.setState({ loader: false });
        this.toasterService.notify('error', 'Invalid Code');
    }
}

    public signup = (res: any) => {

    this.setState({ userEmail: res.email, name: res.name, loader: true });
    let stateData: any = this.state;
    let obj: any = {};
    obj["email"] = res.email;
    obj["fullName"] = res.name;
    if (stateData.userCode && atob(stateData.userCode)) {
        obj["cohortCode"] = atob(stateData.userCode);
    }

    const googleresponse = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
    };

    fetch(API_URL + 'sociallogin', googleresponse)
        .then(async response => {
            this.setState({ loader: false });
            if (!response.ok && !response.headers) {
                this.setState({ userEmail: '', name: '' });
                throw Error(response.statusText);
            }
            this.setState({ userCode: '' });
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson && await response.json();
            if (data && !data.error) {
                this.setState({ userEmail: '', name: '' });
                data["navData"] = {
                    advisorsLink: data.userInfo.role  === RoleEnum.Admin ? true : false ,
                    cohortsLink: false,
                    sequncesLink: false,
                    studentsLink: false,
                    settingLink: false,
                    iscollapsed: false,
                    requestsLink:false,
                    supportLink:false,
                    dashboardLink:data.userInfo.role  !== RoleEnum.Admin ? true : false
                }
                data["editData"] = {
                    editCohort: null,
                    editAdvisor: null,
                    editStudent: null,
                    editSequence: null
                }
                window.sessionStorage.setItem("loginUser", JSON.stringify(data));
                this.toasterService.notify('success', 'Login Successful');
                this.redirectToDashboard(data);
            } else if (data && data.error && data.error.toString().includes('Code does not exists')) {
                this.toasterService.notify('error', 'Login Failure');
            } else {

                this.setState({ showPopup: true });
            }

        })
        .catch(error => {
            this.setState({ loader: false });
            // console.error('There was an error!', error);
        });
}



redirectToDashboard(data:any) {
    setTimeout(() => {
        window.location.href = RoleEnum.Admin === data.userInfo.role ? '/advisors-main' : '/dashboard';
    }, 1000);
   
}

render() {
    return loginTemplate.call(this);
}
}



