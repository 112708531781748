/* eslint-disable */
import React from 'react';

export const ForgotPasswordTemplate = function (this: any) {
    return (

        <div className="login-main">
            {  this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <div className="login-wrapper d-flex justify-content-center align-items-center h-100">
                <div className="login-info">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="login-logo-wrapper h-100 d-flex justify-content-center align-items-center">
                                <div className="w-100">
                                    <div>
                                        <a className="logo"></a>
                                        <a className="logo-title">ProjectYou</a>
                                    </div>
                                    <div className="tag-line-box m-3">
                                        <p className="tag-line">Gain a competitive advantage.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="login-info-wrapper">
                                <div>
                                    <h3 className="login-title">Forgot Password</h3>
                                </div>
                                {this.state.showPopup ? 
                                <div className="conformation-modal modal fade-bg Reset-modal">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"></h5>
                                                <span className="close" onClick={e => this.setState({ showPopup: false })}>
                                                    <span aria-hidden="true">&times;</span>
                                                </span>
                                            </div> 
                                            <div className="modal-body">
                                                <div className="box-wrapper">
                                                    <div className="inputgroup">
                                                        <label htmlFor="username" className="label">Email</label>
                                                        <input type="text" value={this.state.username} onChange={this.onchanges}
                                                            name="username" className="form-control" id="username"
                                                            placeholder="Enter Email Address"></input>
                                                    </div>
                                                </div>
                                                <div className="separator"></div>
                                                <div className="text-center">
                                                    <button type="button" className="btn btn-primary"
                                                    disabled={!this.state.username}
                                                    onClick={e => this.verifyEmail(false)}>Reset Password Page</button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>




                                    {/* <div className="sm-box">
                                        <span className="sm-close-icon" onClick={e => this.setState({ showPopup: false })}>x</span>
                                        <div className="inner-box">
                                            <div className="inputgroup">
                                                <label htmlFor="username" className="label">Email</label>
                                                <input type="text" value={this.state.username} onChange={this.onchanges}
                                                    name="username" className="form-control" id="username"
                                                    placeholder="Enter Email Address"></input>
                                            </div>

                                            <div style={{ textAlign: 'center' }}>
                                                <button type="button" className="btn btn-primary"
                                                    disabled={!this.state.username}
                                                    onClick={e => this.verifyEmail(false)}>Reset Password Page</button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div> : ''}

                                <div className="inputgroup">
                                    <label htmlFor="formGroupExampleInput" className="label">Email</label>
                                    <input type="text" value={this.state.username} onChange={this.onchanges}
                                        name="username"
                                        className="form-control" id="formGroupExampleInput" placeholder="Enter Email Address"></input>
                                </div>
                                <div className="text-center login-btn mb-3">
                                    <input type="button" value={this.loading ? 'Loading...' : 'Submit'}
                                        onClick={e => this.verifyEmail(true)} disabled={!this.state.username} className="btn btn-primary"></input>
                                </div>
                                <div className="text-center">
                                    <a className="forgot-link" onClick={e => this.setState({ showPopup: true, username: '' })}>Already Have a Verification Code.</a>
                                </div>
                                <div className="text-center">
                                    <a className="forgot-link"onClick={this.goToLogin}>Back To Login.</a>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};