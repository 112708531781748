import React from 'react';
import SideNav from '../side-nav/side-nav';

export const SupportTemplate = function (this: any) {
    return (

        <div className="main-container d-flex">
            <SideNav />
            
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">{'Help & Support'}</h2>
                        </div>
                        <div className="separator"></div>
                       
                        <div className="row">
                            <div className="col-md-6 d-flex align-items-stretch">
                                <div className="support-box h-100 w-100">
                                    <div>
                                        <p className="support-title">How can we help ?</p>
                                    </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="inputgroup">
                                            <label className="label">First Name </label>
                                            <input type="text" name="firstName" 
                                             value={this.userInfo?.firstName} disabled
                                                className="form-control"/>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="inputgroup">
                                            <label className="label">Last Name </label>
                                            <input type="text" name="lastName" 
                                             value={this.userInfo?.lastName} disabled
                                                className="form-control"/>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="inputgroup">
                                            <label className="label">Email Address </label>
                                            <input type="text" name="email"
                                             value={this.userInfo?.email} disabled
                                                className="form-control"/>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="inputgroup">
                                            <label className="label">Phone Number</label>
                                            <input type="text" name="phoneNumber"
                                             value={this.userInfo?.phoneNumber} disabled
                                                className="form-control"/>
                                        
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="inputgroup">
                                            <label className="label">Query/Feedback Description </label>
                                            <textarea placeholder="Enter your Query/Feedback" rows={3}
                                                value={this.state.comments} onChange={(e) => this.setState({comments:e.target.value})}
                                                className="form-control text-contol"></textarea>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    <div className="separator"></div>
                                        <div className="text-center">
                                            <button className="btn btn-primary" onClick={(e) => this.sendFormDataToEmail()}
                                             disabled={!this.state.comments} type="button">Submit</button>
                                            {/* <button className="btn btn-primary" type="button">Cancel</button> */}
                                        </div>
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                            <div className="col-md-6 d-flex align-items-stretch">
                            <div className="support-box h-100 w-100 ">
                                <div className="h-100 d-flex align-items-center justify-content-center">
                                    <div className="support-social-info">
                                        <div>
                                            <a className="support-logo"></a>
                                        </div>
                                        <div className="separator"></div>
                                        <ul className="d-flex justify-content-center w-100">
                                            <li><a target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/myprojectyou/" className="insta-icon"></a></li>
                                            <li><a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/my-projectyou" className="linkdin-icon"></a></li>
                                        </ul>
                                    </div>
                                </div>
                            
                            </div>
                                
                            </div>
                        </div>
                       
                    
                    </div>
                </div>
            </div>
        </div>


    )
};
