/* eslint-disable */

import React, { Component } from 'react';
import { logout, isLogin } from '../../../../../utils';
import { Link } from 'react-router-dom';
import { AddSequenceTemplate } from './add-sequence-template';
import { API_URL } from '../../../../../common/constants/url';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import TimezoneSelect from 'react-timezone-select'
import history from '../../../../../config/router/history';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { emailRegEx } from '../../../../../common/constants/regex';
import { SessionType } from '../../../../../common/constants/role-enum';
import { httpMethods } from '../../../../../common/constants/httpEnum';
import { HttpService } from '../../../../../common/services/http-service';
var momenttz = require('moment-timezone');
var moment = require('moment');
export default class AddCohort extends Component {
    public httpService = new HttpService();
    public toasterService = new ToasterService();
    public loggedIn: any = window.sessionStorage.getItem("loginUser");
    public userInfo = this.loggedIn ? JSON.parse(this.loggedIn).userInfo : '';
    public editData = this.loggedIn && JSON.parse(this.loggedIn).editData ?
        JSON.parse(this.loggedIn).editData : {};

    public sessionOptions = [
        { label: 'Select', value: '' },
        { label: SessionType._1on1Session, value: SessionType._1on1Session },
        { label: SessionType._CohortSession, value: SessionType._CohortSession }
    ];
    public reminderOptions = [
        { label: 'Select', value: '' },
        { label: 'Text', value: 'Text' },
        { label: 'Email', value: 'Email' }
    ];
    public reminderTimingOptions = [
        { label: 'Minute(s)', value: 'Minute' },
        { label: 'Hour(s)', value: 'Hour' },
        { label: 'Days(s)', value: 'Day' },
        { label: 'Week(s)', value: 'Week' },


    ];
    public API_URL = API_URL;

    constructor(props: any) {
        super(props);
        this.onchanges = this.onchanges.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.addEvent = this.addEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.search = this.search.bind(this);
        this.editTemplate = this.editTemplate.bind(this);
        this.state = {
            loader: false,
            isStartDateInvalid: false,
            isEndDateInvalid: false,
            activeIndex: 0,
            Sequence: '',
            errormessage: '',
            advisorsData: [],
            StartDate: new Date(),
            filteredItems: [],
            studentData: [],
            sequenceNames: [],
            cohortDetails: {},
            masterEvents: [],
            html: {
                template: `
            \n<h4>Dear User,</h4>\n<p>This is a reminder for your 
            upcoming Cohort Session.</p>\n<p>Topic: 
            <b>{topic}</b></p>\n<p>Session Start Date : 
            <b> {startDate}</b></p>\n\n<br></br>\n<br></br>\n<p>Regards,
            </p>\n<p><b>ProjectYou Team</b></p>`, type: '', showTemplate: false
            },
            events: [{
                id: 1,
                week: '', session: '', firstReminderText: { text: '', htmlString: '' }, sessionType: '', firstReminder: null,
                secondReminder: '', dateOfEvent: null, startTimeOfEvent: null, endTimeOfEvent: null, tz: 'America/Detroit', offset: '-4',
                secondReminderTiming: { 'label': '', 'value': '' }, secondReminderText: { text: '', htmlString: '' },
                firstReminderTiming: { 'label': '', 'value': '' }, guestFacilitator: null, isChecked: false
            }]
        }

    }

    public localDateTime(date: Date, tz?: any) {
        var timeHere = this.tzConverter(date, tz);
        return moment(timeHere).format('ddd, Do MMMM YYYY, h:mma');
    }


    public editTemplate(rowData: any, type: number) {
        let obj: any;
        if (type !== 3) {
            let str: any = `
            \n<h4>Dear User,</h4>\n<p>This is a reminder for your 
            upcoming Cohort Session.</p>\n<p>Topic: 
            <b>{topic}</b></p>\n<p>Session Start Date : 
            <b> {startDate}</b></p>\n\n<br></br>\n<br></br>\n<p>Regards,
            </p>\n<p><b>ProjectYou Team</b></p>`;

            obj = {
                template: type === 1 ? rowData["firstReminderText"]["htmlString"] ?
                    rowData["firstReminderText"]["htmlString"] : str :
                    (rowData["secondReminderText"]["htmlString"] ? rowData["secondReminderText"]["htmlString"]
                        : str), showTemplate: true, type
            };
        } else {
            obj = { template: '', type: null, showTemplate: false };
        }
        this.setState({
            html: obj
        });
    }
    public deleteEvent(rowIndex: number) {
        const stateData: any = this.state;
        if (stateData.events.length) {
            stateData.events.splice(rowIndex, 1);
            this.toasterService.notify('success', 'Event Deleted');
            this.setState({ events: stateData.events });
        }
    }

    public search(event: any) {
        let filteredItems = [];
        let stateData: any = this.state;
        if (stateData.studentData.length && event && event.query) {
            for (let i = 0; i < stateData.studentData.length; i++) {
                let item = stateData.studentData[i];
                if (item.label.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
                    filteredItems.push(item);

                }
            }
            this.setState({ filteredItems });
        }


    }
    public addEvent() {
        const stateData: any = this.state;
        stateData.events.push({
            id: stateData.events.length + 1,
            week: null, session: null, firstReminderText: { text: '', htmlString: '' }, sessionType: null,
            secondReminderText: { text: '', htmlString: '' },
            offset: '-4',
            firstReminder: null, secondReminder: null,
            tz: 'America/Detroit',
            secondReminderTiming: { 'label': '', 'value': '' },
            firstReminderTiming: { 'label': '', 'value': '' },
            dateOfEvent: new Date(), startTimeOfEvent: null,
            endTimeOfEvent: null,
            reminderTiming: null, guestFacilitator: null, isChecked: false
        });
        this.setState({ events: stateData.events })
    }
    componentDidMount() {
        const stateData: any = this.state;
        if (!stateData.advisorsData.length) {
            this.getAdvisorsData();
        }
        if (!stateData.studentData.length) {
            this.getStudentsData();
        }
        if (!stateData.sequenceNames.length) {
            this.getSequenceNames();
        }
        if (this.editData.editSequence) {
            let result = this.editData.editSequence;
            let obj: any = {};
            if (result.events.length) {
                result.events.map((item: any) => {
                    if (item.dateOfEvent) {
                        item.dateOfEvent = new Date(item.dateOfEvent);
                    }
                    if (item.startTimeOfEvent) {
                        item.startTimeOfEvent = new Date(item.startTimeOfEvent);
                    }
                    if (item.endTimeOfEvent) {
                        item.endTimeOfEvent = new Date(item.endTimeOfEvent);
                    }
                });
            }
            obj["_id"] = result._id ? result._id : null;
            obj["events"] = result.events && result.events.length ? result.events : [];
            obj["masterEvents"] = result.events && result.events.length ? JSON.parse(JSON.stringify(result.events)) : [];
            obj["Sequence"] = result.sequence ? result.sequence : null;
            obj["StartDate"] = result.StartDate ? new Date(result.StartDate) : null;
            if (result["cohortCode"]) {
                this.getCohortDetailsByCode(result["cohortCode"]);

            }

            this.setState(obj);

        }

    }

    public getCohortDetailsByCode(cohortCode: any) {
        fetch(API_URL + 'getcohortdetailsbycode',
            this.httpService.httpInterceptor(httpMethods.POST, { cohortCode }))
            .then(async response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();
                if (resdata && resdata.payload) {
                    this.setState({ cohortDetails: resdata.payload });
                }
            })
            .catch(error => {
                //   notify('error', 'Login Failure');
            });
    }
    public getSequenceNames() {
        this.setState({ loader: true });
        fetch(API_URL + 'getsequencenames',
            this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();

                if (resdata && resdata.payload) {
                    this.setState({ sequenceNames: [...resdata.payload] });
                }
            })
            .catch(error => {
                this.setState({ loader: false });

            });
    }
    public getAdvisorsData() {
        this.setState({ loader: true });
        fetch(API_URL + 'getAdvisorsData',
            this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();

                if (resdata && resdata.payload) {
                    let advisorsData: any[] = [];
                    resdata.payload.map((item: any) => {
                        if (item.email) {
                            advisorsData.push({ label: item.fullName, value: item.email })
                        }

                    });
                    this.setState({ advisorsData: [...advisorsData] });
                }
            })
            .catch(error => {
                this.setState({ loader: false });

            });
    }

    public getStudentsData() {

        fetch(API_URL + 'getStudentsData',
            this.httpService.httpInterceptor(httpMethods.GET))
            .then(async response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();

                if (resdata && resdata.payload) {
                    let studentData: any[] = [];
                    resdata.payload.map((e: any) => {
                        studentData.push({ label: e.email, value: e.email });
                    });
                    this.setState({ studentData });
                }
            })
            .catch(error => {

            });
    }

    public gotToSequences() {
        history.push('/sequence-main');
        window.location.reload();
    }

    public onchanges(event: any, rowIndex: number, type?: string) {
        const stateData: any = this.state;
        if (type === 'htmlString' && rowIndex === null) {
            stateData.html["template"] = event;
            this.setState(stateData);
            return;
        }
        if (event && event.target && event.target.name === 'sequence') {
            this.setState({ Sequence: event.target.value });
        } else {
            if (stateData.events.length) {
                stateData.events.map((item: any, index: number) => {

                    let DateEightAM = new Date(new Date(item.dateOfEvent).toDateString() + ' 08:00:00');
                    let DateSixPM = new Date(new Date(item.dateOfEvent).toDateString() + ' 18:01:00');

                    if (index === rowIndex) {
                        if (type === 'htmlString' && rowIndex !== null) {

                            let reminderHTMLType = event === 1 ? "firstReminderText" : "secondReminderText";
                            item[reminderHTMLType]["htmlString"] = stateData.html["template"];
                        }
                        else if (type === 'date') {

                            item.dateOfEvent = event.value;
                            item.endTimeOfEvent = null;
                            item.startTimeOfEvent = null;

                        } else if (type === 'endDate') {

                            item.endTimeOfEvent = event.value;
                            item.startTimeOfEvent = item.dateOfEvent;
                        }
                        else if (type === 'startTime') {
                            let start = new Date(new Date(item.dateOfEvent).toDateString() + ' ' +
                                new Date(event.value).toTimeString());
                            item.startTimeOfEvent = start;

                            let isInvalid = moment(new Date(start)).isBefore(moment(DateEightAM))
                                || moment(new Date(start)).isAfter(moment(DateSixPM));

                            this.setState({ isStartDateInvalid: isInvalid ? true : false });

                            //  console.log(this.convertEndTime( item.dateOfEvent,start));

                        } else if (type === 'endTime') {

                            let endDate = new Date(new Date(item.dateOfEvent).toDateString() + ' ' +
                                new Date(event.value).toTimeString());
                            item.endTimeOfEvent = endDate;

                            let isInvalid = moment(new Date(endDate)).isBefore(moment(DateEightAM))
                                || moment(new Date(endDate)).isAfter(moment(DateSixPM));

                            this.setState({ isEndDateInvalid: isInvalid ? true : false });

                        } else if (type === 'tz') {
                            item.tz = event.value;
                            item.offset = event.offset;
                        }
                        else if (event.target.name === 'firstReminderText' ||
                            event.target.name === 'secondReminderText') {
                            item[event.target.name]['text'] = event.target.value
                        }
                        else if (event.target.name === 'isChecked') {
                            item[event.target.name] = event.target.checked
                        } else {
                            item[event.target.name] = event.target.value
                        }
                    }
                });
                this.setState({ events: stateData.events, html: { template: '', type: null, showTemplate: false } });
            }
        }


    }




    convertEndTime(date: any) {
        if (date && new Date(date)) {
            let mnth = ('0' + (date.getMonth() + 1)).slice(-2);
            let day = ('0' + date.getDate()).slice(-2);
            let hh = ('0' + date.getHours()).slice(-2)
            let year = date.getFullYear();
            let mm = ('0' + date.getMinutes()).slice(-2);
            let ss = ('0' + date.getSeconds()).slice(-2);

            return year + '-' + mnth + '-' + day + 'T' + hh + ':' + mm + ':' + ss;
        } else {
            return null;
        }
    }
    public reminderChanges(event: any, rowIndex: number, type: string, option: string) {
        if (option === 'value' && event.target.value && (event.target.value < 1 || event.target.value > 100 || isNaN(event.target.value))) {
            return;
        } else {
            const stateData: any = this.state;
            if (stateData.events.length) {
                stateData.events.map((item: any, index: number) => {
                    if (index === rowIndex) {
                        if (type === '1') {
                            item.firstReminderTiming[option] = event.target.value;
                        } else {
                            item.secondReminderTiming[option] = event.target.value;
                        }
                    }
                });
                this.setState({ events: stateData.events });
            }
        }

    }


    public getReminderDate(reminderDateObj: any, startDateTime: any) {
        if (reminderDateObj.value && reminderDateObj.label && startDateTime) {
            switch (reminderDateObj.label) {
                case 'Minute':
                    return new Date(moment(new Date(startDateTime)).subtract(reminderDateObj.value, 'minutes').format()).toLocaleString();
                case 'Hour': return new Date(moment(new Date(startDateTime)).subtract(reminderDateObj.value, 'hours').format()).toLocaleString();
                case 'Week': return new Date(moment(new Date(startDateTime)).subtract(reminderDateObj.value, 'weeks').format()).toLocaleString();
                case 'Day': return new Date(moment(new Date(startDateTime)).subtract(reminderDateObj.value, 'days').format()).toLocaleString();
            }
        }
    }

    public submitForm(event: any) {
        this.setState({ loader: true });

        event.preventDefault();
        let stateData: any = this.state;
        if (!stateData._id && stateData.sequenceNames.length && stateData.sequenceNames.find((x: any) => x.toLowerCase().trim() === (stateData.Sequence).toLowerCase().trim())) {
            this.toasterService.notify('error', 'Sequence name already exists');
            this.setState({ loader: false });
            return;
        }
        if (stateData && stateData.Sequence && stateData.events.length &&
            !stateData.events.find((x: any) => (x.week === null || x.startTimeOfEvent === null ||
                x.endTimeOfEvent === null || x.sessionType === null
            ))
        ) {

            if (stateData.isStartDateInvalid || stateData.isEndDateInvalid) {
                this.toasterService.notify('error', 'Please select valid timings');
                this.setState({ loader: false });
                return;
            }
            
            if (stateData.events.find((x: any) => x.sessionType === 'Cohort Session' &&
                (x.firstReminder === null || !x.firstReminderTiming.value || !x.firstReminderTiming.label))) {
                this.toasterService.notify('error', 'Please fill all the mandatory fields');
                this.setState({ loader: false });
                return;
            }
            if (stateData.events.find((x: any) => new Date(x.startTimeOfEvent) > new Date(x.endTimeOfEvent))) {
                this.toasterService.notify('error', 'Start time should be less than End time');
                this.setState({ loader: false });
                return;
            }
            if (stateData.events.find((x: any) => x.sessionType === 'Cohort Session' &&
                (x.guestFacilitator !== null && !emailRegEx.test(x.guestFacilitator)))) {
                this.toasterService.notify('error', 'Invalid Guest Facilitator(s) Email');
                this.setState({ loader: false });
                return;
            }
            let eventsClone = JSON.parse(JSON.stringify(stateData.events));
            let isError = false;
            stateData.events.map((e: any, i: any) => {
                eventsClone.map((k: any, j: any) => {
                    if (i !== j) {
                        if (new Date(k.startTimeOfEvent) >= new Date(e.startTimeOfEvent) &&
                            new Date(k.startTimeOfEvent) <= new Date(e.endTimeOfEvent)) {
                            isError = true;
                        }
                        if (new Date(k.endTimeOfEvent) >= new Date(e.startTimeOfEvent)
                            && new Date(k.endTimeOfEvent) <= new Date(e.endTimeOfEvent)) {
                            isError = true;
                        }
                    }

                });

            });
            if (isError) {
                this.toasterService.notify('error', 'Dates are Overlapping');
                this.setState({ loader: false });
                return;
            }
            let selectedEvents: any[] = stateData.events;
            if (stateData._id && stateData.masterEvents.length && stateData.events.length) {
                stateData.masterEvents.map((item: any) => {
                    selectedEvents = selectedEvents.filter(x => JSON.stringify(x) !== JSON.stringify(item));
                });


            }

            const requestData = {
                email: this.userInfo.email,
                events: stateData.events,
                sequence: stateData.Sequence,
                _id: stateData._id ? stateData._id : null,
                cohortDetails: _.isEmpty(stateData.cohortDetails) ? null :
                    stateData.cohortDetails,
                startDate: new Date(),
                selectedEvents: selectedEvents.length ? selectedEvents :
                    []
            };

            for (let index = 0; index < requestData.events.length; index++) {
                const element = requestData.events[index];
                element.startTimeOfEvent = this.tzConverter(element.startTimeOfEvent, element.tz);
                element.endTimeOfEvent = this.tzConverter(element.endTimeOfEvent, element.tz);
            
                console.log("session name:::" + element.session);
                console.log("startTime::" +  moment(element.startTimeOfEvent).format("dddd, MMMM Do YYYY"));
                console.log("endTime::" + moment(element.endTimeOfEvent).format("dddd, MMMM Do YYYY"));

            }

            fetch(this.API_URL + 'addsequence',
                this.httpService.httpInterceptor(httpMethods.POST, requestData))
                .then(async response => {
                    this.setState({ loader: false });
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    if (data && data.payload) {
                        this.toasterService.notify('success', stateData._id ?
                            'Sequence Successfully Updated' : 'Sequence Successfully Added');
                        setTimeout(() => {
                            this.gotToSequences();
                        }, 2000);
                    } else if (!data.payload && data.status === 'success') {
                        setTimeout(() => {
                            this.gotToSequences();
                        }, 2000);

                    } else {
                        this.toasterService.notify('error', 'Failed to Add Sequence');
                    }
                })
                .catch(error => {
                    this.setState({ loader: false });
                    //   this.toasterService.notify('error', 'Login Failure');
                });
        } else {
            const stateData: any = this.state;
            if (stateData.isStartDateInvalid || stateData.isEndDateInvalid) {
                this.toasterService.notify('error', 'Please select valid timings');
                this.setState({ loader: false });
                return;
            }

            this.toasterService.notify('error', stateData.Sequence ?
                ((stateData.events.length && stateData.events.find((x: any) => (x.week === null ||
                    x.startTimeOfEvent === null || x.endTimeOfEvent === null || x.sessionType === null))) ?
                    'Please fill all the fields' : 'Please Add atleast 1 event') :
                'Please enter sequence');
            this.setState({ loader: false });
        }
    }

    tzConverter(timeOfEvent: any, tz: any) {
        const mom = moment(timeOfEvent);
        const currOffset = moment().utcOffset();
        const trgtOffset = moment.tz(tz).utcOffset();
        var diff = currOffset - trgtOffset;
        const newTime = mom.add(diff, 'minutes');
        return newTime.valueOf();
    }

    revrseTzConverter(timeOfEvent: any, targetTz: any) {
        const mom = moment(timeOfEvent);
        const sourceOffset = moment().utcOffset();
        const trgtOffset = moment.tz(targetTz).utcOffset();
        var diff = trgtOffset - sourceOffset;
        const newTime = mom.add(diff, 'minutes');
        return new Date(newTime.valueOf());
    }

    render() {

        return AddSequenceTemplate.call(this);
    }

}