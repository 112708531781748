/* eslint-disable */
import React from 'react';

export const ResetPasswordTemplate = function (this: any) {
  return (

    <div className="login-main">
      {  this.state.loader ?
        <div className="loader-wrapper">
          <div className="loader">Loading...</div>
        </div> : ''
      }
      <div className="login-wrapper d-flex justify-content-center align-items-center h-100">
        <div className="login-info">
          <div className="row">
            <div className="col-md-6">
              <div className="login-logo-wrapper h-100 d-flex justify-content-center align-items-center">
                <div className="w-100">
                  <div>
                    <a className="logo"></a>
                    <a className="logo-title">ProjectYou</a>
                  </div>
                  <div className="tag-line-box">
                    <p className="tag-line">Gain a competitive advantage.</p>
                  
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="login-info-wrapper">
                <div>
                  <h3 className="login-title">Reset Password</h3>
                </div>

                
                { this.state.case === 1?  <div className="inputgroup">
                  <label htmlFor="otp" className="label">Verification Code*</label>
                  <input type="number" value={this.state.otp} onChange={this.onchanges}
                    name="otp" maxLength={4}
                    className="form-control" id="otp" placeholder="Verification Code"></input>
                </div> : ''}
                <div className="inputgroup">
                  <label htmlFor="password" className="label">New Password*</label>
                  <input type="password" value={this.state.password} onChange={this.onchanges}
                    name="password"
                    className="form-control" id="password" placeholder="Enter New Password"></input>
                </div>
                <div className="inputgroup">
                  <label htmlFor="confirmPassword" className="label">Confirm New Password*</label>
                  <input type="password" value={this.state.confirmPassword} onChange={this.onchanges}
                    name="confirmPassword"
                    className="form-control" id="confirmPassword" placeholder="Confirm New Password"></input>
                </div>
                <div className="text-center login-btn mb-3">
              { this.state.case === 1?  <input type="button"  value={'Login'}
                  onClick={this.resetPassword} disabled={!this.state.otp || !this.state.password || !this.state.isValidOTP ||
                  (this.state.password !== this.state.confirmPassword)} className="btn btn-primary"></input> : '' }
                     { this.state.case === 2?  <input type="button"  value={'Login'}
                  onClick={this.resetPassword} disabled={!this.state.password || !this.state.isValidOTP ||
                  (this.state.password !== this.state.confirmPassword)} className="btn btn-primary"></input> : '' }
                </div>
                { this.state.case === 1? <div className="text-center">
                    <p className="forgot-link" onClick={this.resendOTP}>Resend Verification Code.</p>
                </div> : ''}
                <div className="text-center">
                <p className="forgot-link" onClick={this.goToLogin}>Back To Login.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};