
import React from 'react';
import "./header.scss";
import logo from '../../../assets/images/user.png';
export const HeaderTemplate = function (this: any) {
  return (
    <header>
      <div className="container-fluid">
        <div className="navbar d-flex justify-content-between align-items-center">
          <div className="logo-wrapper">
            <ul className="d-flex align-items-center">
              <li><a className="logo"></a></li>
            </ul>
          </div>
          <div className="menu-wrapper">
            <ul className="d-flex align-items-center">
              <li><a href="javascript:void(0)" onClick={this.goToDashboard} className="home-icon"></a></li>
              {/* {this.role === RoleEnum.Admin ?<li> <a className="setting-icon" onClick={this.goToSettings}></a></li>: ''} */}
              <li>
                <div className="profile-info d-flex">
                  <a className="d-flex cursor" onClick={this.toggle}>
                  <div className="profile-text">
                    <label className="user-title mb-0"> {this.name}</label>
                    <label className="welcome-text"><strong>{this.role}</strong></label>
                  </div>
                  <div className="user-profile">
                    <img src={logo}></img>
                  </div>
                  </a>
                  <div >

                    <div className="dropdown b-left mr-3">
                      {/* <a onClick={this.toggle}>
                        <span className="ellipsis-icon" id="showDropdown"></span>
                      </a> */}
                      {this.state.isToggle ? <ul className="dropdown-menu">
                        <li><a onClick={this.goToProfile}><span className="profile-icon mr-2"></span>Profile</a></li>
                        <li><a onClick={this.logout}><span className="logout-icon mr-2"></span>Logout</a></li>

                      </ul> : ''}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header >
  )
};
