import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyCUBDP2ARviWuoYLGk06As7EeQT6z2FXlw",
    authDomain: "projectyou-1ec95.firebaseapp.com",
    projectId: "projectyou-1ec95",
    storageBucket: "projectyou-1ec95.appspot.com",
    messagingSenderId: "603494982388",
    appId: "1:603494982388:web:c3b3f16f352ee19c6fb4a1"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;