/* eslint-disable */
import React, { Component, useState } from 'react';
import { DashboardTemplate } from './dashboard-template';
import { DateFormatService } from '../../../common/services/date-format-service';
import { ToasterService } from '../../../common/services/toastr-service';
import { RoleEnum } from '../../../common/constants/role-enum';
import { API_URL, PROD_URL } from '../../views/../../common/constants/url';
import { httpMethods } from '../../../common/constants/httpEnum';
import { HttpService } from '../../../common/services/http-service';
var momenttz = require('moment-timezone');
var moment = require('moment');

export default class Dashboard extends Component {
  session: any = window.sessionStorage.getItem("loginUser");
  loggedIn = (typeof this.session === 'string') ? JSON.parse(this.session) : this.session;
  public toasterService = new ToasterService();
  public dateFormatService = new DateFormatService();
  public httpService = new HttpService();
  constructor(props: any) {
    super(props);
    this.state = {
      loader: false,
      data: [],
      showPopUp: false,
      showCalendar: false,
      isStartDateInvalid: false,
      isEndDateInvalid: false,
      sessionDetails: {},
      dateOfEvent: new Date(),
      startTimeOfEvent: null,
      endTimeOfEvent: null,
      advisorData: {},
      requestedData: [],
      requestedEvents: [],
      showCohorts: false,
      cohortData: [],
      cohortDataClone:[],
      tz:'',
      isActive:false
    }
    this.onchanges = this.onchanges.bind(this);
    this.showPopUp = this.showPopUp.bind(this);
    this.getStudentData = this.getStudentData.bind(this);
    this.requestSession = this.requestSession.bind(this);
    this.getAdvisorData = this.getAdvisorData.bind(this);
    this.getRequestsData = this.getRequestsData.bind(this);
    this.iframe = this.iframe.bind(this);
    this.getCohortDataByEmail = this.getCohortDataByEmail.bind(this);


  }

  public getRequestsData() {
    this.setState({ loader: true });
    let requestData = {}
    if (this.loggedIn.userInfo.role === RoleEnum.Student) {
      requestData = { requestedBy: this.loggedIn.userInfo.email }
    } else {
      requestData = { requestedTo: this.loggedIn.userInfo.email }
    }

    fetch(API_URL + 'getrequestsdata', this.httpService.httpInterceptor(httpMethods.POST, requestData))
      .then(async response => {
        this.setState({ loader: false });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();
        if (resdata && resdata.payload) {
          this.setState({ requestedData: [...resdata.payload] });
        }
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  public getCohortDataByEmail() {
    this.setState({ loader: true });
    fetch(API_URL + 'getcohortdatabyEmail',
      this.httpService.httpInterceptor(httpMethods.POST,
        { email: this.loggedIn.userInfo.email }))
      .then(async response => {
        this.setState({ loader: false });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();
        if (resdata && resdata.payload) {
          this.setState({ cohortData: [...resdata.payload] ,cohortDataClone:[...resdata.payload]});
        }
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }




  componentDidMount() {
    const stateData: any = this.state;
    if (!stateData.data.length && this.loggedIn && this.loggedIn.userInfo &&
      this.loggedIn.userInfo.role && this.loggedIn.userInfo.role === RoleEnum.Student) {
      this.getStudentData();
    } else if (!stateData.data.length && this.loggedIn && this.loggedIn.userInfo &&
      this.loggedIn.userInfo.role && this.loggedIn.userInfo.role === RoleEnum.Advisor) {
      this.getAdvisorData();
    }
    if (!stateData.cohortData.length && this.loggedIn && this.loggedIn.userInfo &&
      this.loggedIn.userInfo.role && this.loggedIn.userInfo.role === RoleEnum.Advisor) {
      this.getCohortDataByEmail();
    }
  }

  showPopUp(rowData: any, type: string) {
    let stateData: any = this.state;
    let row = JSON.parse(JSON.stringify(rowData));
    let events = [];
    if (this.loggedIn.userInfo.role === RoleEnum.Student && stateData.requestedData.length) {
      stateData["requestedEvents"] = stateData.requestedData.filter((item: any) => item.requestedBy === this.loggedIn.userInfo.email &&
        item.session.toLowerCase() === rowData.session.toLowerCase());
    }
    stateData[type] = true;
    stateData["sessionDetails"] = row;
    stateData["dateOfEvent"] = new Date();
    stateData["startTimeOfEvent"] = null;
    stateData["endTimeOfEvent"] = null;
    stateData['tz'] = '';
    this.setState(stateData);
  }

  public onchanges(event: any, type?: string) {
    let stateData: any = this.state;

    let DateEightAM = new Date(new Date(stateData.dateOfEvent).toDateString() + ' 08:00:00');
    let DateSixPM = new Date(new Date(stateData.dateOfEvent).toDateString() + ' 18:01:00');
    if (type === 'date') {
      stateData.dateOfEvent = event.value;
    } else if (type === 'startTime') {
      let start = new Date(new Date(stateData.dateOfEvent).toDateString() + ' ' +
        new Date(event.value).toTimeString())
      stateData.startTimeOfEvent = start;

      let isInvalid = moment(new Date(start)).isBefore(moment(DateEightAM))
      || moment(new Date(start)).isAfter(moment(DateSixPM));
      stateData.isStartDateInvalid = isInvalid ? true : false;
    } else if (type === 'endTime') {
      let endDate = new Date(new Date(stateData.dateOfEvent).toDateString() + ' ' +
        new Date(event.value).toTimeString())
      stateData.endTimeOfEvent = endDate;

      let isInvalid = moment(new Date(endDate)).isBefore(moment(DateEightAM))
        || moment(new Date(endDate)).isAfter(moment(DateSixPM));
        stateData.isEndDateInvalid = isInvalid ? true : false;
    } else if (type === 'tz') {
      stateData.tz = event;
    }
    this.setState(stateData);


  }

  public requestSession() {
    const stateData: any = this.state;
    if (new Date(stateData.startTimeOfEvent) >= new Date(stateData.endTimeOfEvent)) {
      this.toasterService.notify('error', 'End time should be greater than Start time');
      return;
    }
    if (stateData.dateOfEvent && stateData.endTimeOfEvent &&
      stateData.startTimeOfEvent) {

      this.setState({ loader: true });

      if (stateData.isStartDateInvalid || stateData.isEndDateInvalid) {
        this.toasterService.notify('error', 'Please select valid timings');
        this.setState({ loader: false });
        return;
    }
    
      const requestData = {
        "sequence": stateData.sessionDetails.sequence,
        "session": stateData.sessionDetails.session,
        "dateOfEvent": stateData.dateOfEvent,
        "startTimeOfEvent": stateData.startTimeOfEvent,
        "endTimeOfEvent": stateData.endTimeOfEvent,
        "status": "Pending For Approval",
        "requestedBy": this.loggedIn.userInfo.email,
        "requestedTo": stateData.advisorData.email,
        "reasonOfRejection": null,
        "cohortCode": this.loggedIn.userInfo.cohortCode,
        "studentName": this.loggedIn.userInfo.fullName,
        "tz": stateData.tz

      };
      fetch(API_URL + 'addeditrequest',
        this.httpService.httpInterceptor(httpMethods.POST, requestData))
        .then(async response => {
          this.setState({ loader: false });
          if (!response.ok && !response.headers) {
            throw Error(response.statusText);
          }
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (data && data.payload && !data.error) {
            this.toasterService.notify('success', 'Request Sent Successfully');
            this.setState({
              loader: false, showCalendar: false, sessionDetails: {},
              dateOfEvent: new Date(),
              startTimeOfEvent: null,
              endTimeOfEvent: null
            });
          } else if (data.error) {
            this.toasterService.notify('error', data.error);
          }
        })
        .catch(error => {
          this.setState({ loader: false });
          //   this.toasterService.notify('error', 'Login Failure');
        });
    } else {
      this.toasterService.notify('error', 'Please fill all the fields');
    }

  }

  iframe() {
    let stateData: any = this.state;
    return {
      __html: this.loggedIn.userInfo.role === RoleEnum.Student ?
        stateData.advisorData.iFrame : this.loggedIn?.userInfo?.iFrame
    }
  }

  public revrseTzConverter(timeOfEvent: any, targetTz: any) {
    const mom = moment(timeOfEvent);
    const sourceOffset = moment().utcOffset();
    const trgtOffset = moment.tz(targetTz).utcOffset();
    var diff = trgtOffset - sourceOffset;
    const newTime = mom.add(diff, 'minutes');
    return new Date(newTime.valueOf());
}

  public getStudentData() {
    const requestData = {
      cohortCode: this.loggedIn?.userInfo?.cohortCode
    };
    fetch(API_URL + 'getsessionsbycode',
      this.httpService.httpInterceptor(httpMethods.POST, requestData))
      .then(async response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata: any = isJson && await response.json();

        if (resdata && resdata.payload.sequenceData) {
          resdata.payload.sequenceData.events.map((item: any) => {
            item["sequence"] = resdata.payload.sequenceData.sequence;
            item.startTimeOfEvent = this.revrseTzConverter(item.startTimeOfEvent, item.tz);
            item.endTimeOfEvent = this.revrseTzConverter(item.endTimeOfEvent, item.tz);
          });
          this.setState({ data: resdata.payload.sequenceData.events });
        }
        if (resdata && resdata.payload.advisorData) {
          this.setState({ advisorData: resdata.payload.advisorData });
        }
        if (resdata && resdata.payload.cohortData) {
          this.setState({ cohortData: [resdata.payload.cohortData],cohortDataClone: [resdata.payload.cohortData] });
        }

      })
      .catch(error => {
        //  error
      });
  }
  public getAdvisorData() {
    const requestData = {
      advisorEmail: this.loggedIn?.userInfo?.email
    };
    fetch(API_URL + 'getsessionbyemail',
      this.httpService.httpInterceptor(httpMethods.POST, requestData))
      .then(async response => {
        if (!response.ok) { //sessionType
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata: any = isJson && await response.json();

        if (resdata && resdata.payload.length) {
          let dataClone: any[] = [];
          resdata.payload.map((e: any) => {
            e.events.map((item: any) => {
              item["sequence"] = e.sequence;
              item.startTimeOfEvent = this.revrseTzConverter(item.startTimeOfEvent, item.tz);
              item.endTimeOfEvent = this.revrseTzConverter(item.endTimeOfEvent, item.tz);
              dataClone.push(item);
            });
          });
          if (dataClone.length) {
            // dataClone = dataClone.filter((x: any) => new Date(x.dateOfEvent) >= new Date());
            this.setState({ data: dataClone });
          }
        }
      })
      .catch(error => {
        //  error
      });
  }
  setDate(rowData: any) {

    return <span>{(rowData.dateOfEvent)}</span>;
  }

  render() {
    return DashboardTemplate.call(this);
  }


}



