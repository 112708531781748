import React from 'react';
import SideNav from '../../../side-nav/side-nav';

export const AddAdvisorTemplate = function (this: any) {
    return (


        <div className="main-container d-flex">
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title"> Add Advisor</h2>
                            <a className="back-btn" onClick={this.gotToAdvisors}><span className="back-icon"></span>Back</a>
                        </div>
                        <div className="separator"></div>
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="inputgroup">
                                        <label className="label"> First Name </label>
                                        <input type="text"  name="firstName" placeholder="Enter First Name"
                                            value={this.state.firstName} onChange={this.onchanges}
                                            className="form-control" required />
                                        {this.state.errormessage}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="inputgroup">
                                        <label className="label"> Last Name </label>
                                        <input type="text" name="lastName" placeholder="Enter Last Name"
                                            value={this.state.lastName} onChange={this.onchanges}
                                            className="form-control" required />
                                        {this.state.errormessage}
                                    </div>
                                </div>

                                 <div className="col-md-6">
                                    <div className="inputgroup">
                                        <label htmlFor="formGroupExampleInput" className="label">Email</label>
                                        <input type="email" value={this.state.email} onChange={this.onchanges}
                                            name="email" disabled={this.state._id}
                                            className="form-control" id="formGroupExampleInput" placeholder="Enter Email Address"></input>
                                    </div>
                                </div>    

                                <div className="col-md-6">
                                    <div className="inputgroup">
                                            <label htmlFor="formGroupPhoneNumber" className="label">Phone Number</label>
                                            <input type="text" value={this.state.phoneNumber} onChange={this.onchanges}
                                                name="phoneNumber"
                                                className="form-control" id="formGroupPhoneNumber" placeholder="Enter Phone Number"></input>
                                        </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="inputgroup">
                                        <label htmlFor="formGroupExampleInput" className="label">Zoom User ID</label>
                                        <input type="zoomId" value={this.state.zoomId} onChange={this.onchanges}
                                            name="zoomId"
                                            className="form-control" id="formGroupExampleInput" placeholder="Enter Zoom User ID/ Zoom Registered Email"></input>
                                    </div>
                                </div>  
                                <div className="col-md-12">
                                <div className="separator"></div>
                                    <div className="d-flex justify-content-center">
                                        <div className="">
                                            <button className="btn btn-primary mr-3" type="submit"
                                                onClick={this.submitForm}>Save</button>
                                        </div>
                                        <div className="">
                                            <button className="btn btn-primary" type="button"
                                                onClick={this.gotToAdvisors}
                                            >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
};
