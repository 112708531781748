/* eslint-disable */

import React, { Component } from 'react';
import { AddStudentTemplate } from './add-student-template';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../../common/constants/url';
import { emailRegEx, nameRegEx } from '../../../../../common/constants/regex';
import history from '../../../../../config/router/history';
import * as XLSX from 'xlsx';
import * as  _ from 'lodash';

import { DataService } from '../../../../../common/services/data-service';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { httpMethods } from '../../../../../common/constants/httpEnum';
import { HttpService } from '../../../../../common/services/http-service';
export default class AddAdvisor extends Component {
    public toasterService = new ToasterService();
    public httpService = new HttpService();
    public dataService = new DataService();
    public csv: any = [["FirstName", "LastName", "Email", "PhoneNumber"]];
    public loggedIn = window.sessionStorage.getItem("loginUser");
    public editData = this.loggedIn && JSON.parse(this.loggedIn).editData ?
        JSON.parse(this.loggedIn).editData : {};
    myRef: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        this.myRef = React.createRef();
        this.onchanges = this.onchanges.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.removeDuplicates = this.removeDuplicates.bind(this);
        this.state = {
            loader: false,
            firstName: '',
            lastName: '',
            email: '',
            errormessage: '',
            isBulkUpload: false,
            jsonData: [],
            duplicateEmails: [],
            _id: null,
            fileName: ''

        }
    }
    componentDidMount() {
        if (this.editData.editStudent) {
            let result = this.editData.editStudent;
            let obj: any = {};
            obj["_id"] = result._id ? result._id : null;
            obj["firstName"] = result.firstName ? result.firstName : null;
            obj["lastName"] = result.lastName ? result.lastName : null;
            obj["email"] = result.email ? result.email : null;
            obj["phoneNumber"] = result.phoneNumber ? result.phoneNumber : null;
            this.setState(obj);

        }

    }


    public onFormCancel() {
        const stateData: any = this.state;
        if (stateData.isBulkUpload) {
            this.myRef.current.value = '';
            this.setState({ duplicateEmails: [], jsonData: [], fileName: '' })
        } else {
            this.gotToStudents()
        }
    }
    public removeDuplicates() {
        this.setState({ loader: true });
        const stateData: any = this.state;
        let duplicateEmails = stateData.duplicateEmails;
        let jsonData = stateData.jsonData;
        if (duplicateEmails.length === jsonData.length) {
            duplicateEmails = [];
            jsonData = [];
            this.myRef.current.value = '';
            this.setState({ duplicateEmails: [], jsonData: [], fileName: '' })
            this.toasterService.notify('success', 'Removed Successfully');
        } else if (stateData.duplicateEmails.length && stateData.jsonData.length) {
            duplicateEmails.map((e: any) => {
                _.remove(jsonData, function (el: any) {
                    return el["email"].toLowerCase() === e.toLowerCase();
                });

            });
            if (duplicateEmails.length) {
                this.setState({ duplicateEmails: [], jsonData: jsonData })
                this.toasterService.notify('success', 'Removed Successfully');
            }
        }
        this.setState({ loader: false });
    }
    public gotToStudents() {
        history.push('/students-main');
        window.location.reload();
    }

    public onchanges(event: any) {
        switch (event.target.name) {
            case 'email': this.setState({ email: event.target.value }); break;
            case 'firstName': this.setState({ firstName: event.target.value }); break;
            case 'lastName': this.setState({ lastName: event.target.value }); break;
            case 'phoneNumber':
                this.setState({ phoneNumber: this.dataService.formatNumber(event.target.value) });
                break;

        }
    }

    public changeTab() {
        this.setState({ loader: true });
        const stateData: any = this.state;
        if (stateData.isBulkUpload) {
            this.setState({ isBulkUpload: false, fileName: '' });
        } else {
            this.setState({
                isBulkUpload: true, firstName: '',
                lastName: '',
                email: '',
                errormessage: '',
                phoneNumber: ''
            });
        }
        this.setState({ loader: false });
    }


    public submitForm(event: any) {
        this.setState({ loader: true });
        event.preventDefault();
        let stateData: any = this.state;
        if (stateData.isBulkUpload) {
            stateData.jsonData = stateData.jsonData.length ? stateData.jsonData.map((item: any) => {
                const obj: any = {};
                Object.keys(item).map((key: any) => {
                    obj[key.toUpperCase().trim()] = item[key].toString();
                });
                return obj;
            }) : [];
            console.log(stateData.jsonData[0]);
            if (stateData.jsonData.length && _.isEqual(Object.keys(stateData.jsonData[0]),
                ['FIRSTNAME', 'LASTNAME', 'EMAIL'])) {
                    console.log("0002");
                let copyJsonData: any[] = [];
                JSON.parse(JSON.stringify(stateData.jsonData)).map((e: any) => {
                    console.log("0003");
                    copyJsonData.push({
                        firstName: (e['FIRSTNAME']).toString().trim(),
                        lastName: e['LASTNAME'],
                        email: e['EMAIL'],
                        fullName: e['FIRSTNAME'] + " " + e['LASTNAME'],
                        phoneNumber: e['PHONENUMBER']?this.dataService.formatNumber(e['PHONENUMBER']):undefined
                    })
                });

                fetch(API_URL + 'bulkuploadstudents',
                    this.httpService.httpInterceptor(httpMethods.POST, copyJsonData))
                    .then(async response => {
                        this.setState({ loader: false });
                        if (!response.ok && !response.headers) {
                            throw Error(response.statusText);
                        }
                        const isJson = response.headers.get('content-type')?.includes('application/json');
                        const data = isJson && await response.json();
                        if (data && data.payload && !data.error) {
                            this.toasterService.notify('success', 'Data Uploaded Successfully');
                            this.gotToStudents();
                        } else if (data.error) {
                            this.toasterService.notify('error', data.error);
                        } else {
                            this.toasterService.notify('error', 'Failed to Upload Data');
                        }
                    })
                    .catch(error => {
                        this.setState({ loader: false });
                        //   this.toasterService.notify('error', 'Login Failure');
                    });
            } else if (!stateData.jsonData.length) {
                this.toasterService.notify('error', 'No Data Available to Upload');
                this.setState({ loader: false });
            } else {
                this.toasterService.notify('error', 'Incorrect Headers');
                this.setState({ loader: false });
            }
        } else {
            if (stateData && stateData.email && stateData.firstName &&
                nameRegEx.test(stateData.firstName) && nameRegEx.test(stateData.lastName) &&
                emailRegEx.test(stateData.email)) {
                const requestData = {
                    fullName: (stateData.firstName).toString().trim() + " " + stateData.lastName,
                    firstName: stateData.firstName,
                    lastName: stateData.lastName,
                    email: stateData.email,
                    phoneNumber: stateData.phoneNumber,
                    _id: stateData._id ? stateData._id : null

                };
                fetch(API_URL + 'addstudent',
                    this.httpService.httpInterceptor(httpMethods.POST, requestData))
                    .then(async response => {
                        this.setState({ loader: false });
                        if (!response.ok && !response.headers) {
                            throw Error(response.statusText);
                        }
                        const isJson = response.headers.get('content-type')?.includes('application/json');
                        const data = isJson && await response.json();
                        if (data && data.payload && !data.error) {
                            this.toasterService.notify('success', 'Student Successfully Added');
                            this.gotToStudents();
                        } else if (data && data.error) {
                            this.toasterService.notify('error', data.error);
                            this.setState({ loader: false });
                        }
                        else {
                            this.toasterService.notify('error', 'Failed to Add Student');
                            this.setState({ loader: false });
                        }
                    })
                    .catch(error => {
                        this.setState({ loader: false });
                        //   this.toasterService.notify('error', 'Login Failure');
                    });


            } else if (stateData.email && !emailRegEx.test(stateData.email)) {
                this.toasterService.notify('error', 'Please Enter a Valid Email Id');
                this.setState({ loader: false });
            } else if (stateData.firstName && !nameRegEx.test(stateData.firstName)) {
                this.toasterService.notify('error', 'Please Enter a valid First name');
                this.setState({ loader: false });
            } else if (stateData.lastName && !nameRegEx.test(stateData.lastName)) {
                this.toasterService.notify('error', 'Please Enter a valid Last name');
                this.setState({ loader: false });
            } else {
                this.toasterService.notify('error', 'Please fill all the fields');
                this.setState({ loader: false });
            }
        }

    }
    handleFileUpload = (ev: any) => {
        let workBook: any = null;
        let jsonData: any = null;
        this.setState({ jsonData: [], fileName: ev.target.files[0].name });
        const reader = new FileReader();
        const file = ev.target.files[0];
        reader.onload = (event) => {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            jsonData["Sheet1"] = jsonData["Sheet1"].map((item: any) => {
                const obj: any = {};
                Object.keys(item).map((key: any) => {
                    obj[key.toLowerCase().trim()] = item[key].toString();
                });
                return obj;
            });
            if (jsonData["Sheet1"].length &&
                !(jsonData["Sheet1"]).every((e: any) => emailRegEx.test(e.email))) {
                this.toasterService.notify('error', 'Invalid Email(s)');
                return;
            }
            if (jsonData["Sheet1"].length &&
                jsonData["Sheet1"].find((x: any) => !x.firstname || !x.email )) {
                this.toasterService.notify('error', 'Please fill the blank field(s)');
                return;
            }
            if (jsonData["Sheet1"].length &&
                jsonData["Sheet1"].find((x: any) => !nameRegEx.test(x.firstname) || !nameRegEx.test(x.lastname))) {
                this.toasterService.notify('error', 'Invalid name(s)');
                return;
            }
            for (let i = 0; i < jsonData["Sheet1"].length; i++) {
                if (jsonData["Sheet1"][i].phonenumber && jsonData["Sheet1"][i].phonenumber != null && (jsonData["Sheet1"][i].phonenumber).toString().trim().length > 0) {
                    if (!isNaN(jsonData["Sheet1"][i].phonenumber) || (jsonData["Sheet1"][i].phonenumber).toString().length !== 10) {
                        console.log("invalid ph"+jsonData["Sheet1"][i].phonenumber);
                        this.toasterService.notify('error', 'Invalid Phone Number(s)');
                        return;
                    }
                }
            }

            this.setState({ jsonData: jsonData["Sheet1"] });
            const requestData: any = { jsonData: _.map(jsonData["Sheet1"], 'email') };
            fetch(API_URL + 'verifyuploadedfile',
                this.httpService.httpInterceptor(httpMethods.POST, requestData))
                .then(async response => {
                    if (!response.ok && !response.headers) {
                        throw Error(response.statusText);
                    }
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();
                    if (data && data.length) {
                        this.setState({ duplicateEmails: _.map(data, 'email') })
                    }
                })
                .catch(error => {
                    //   this.toasterService.notify('error', ' Failure');
                });


        }
        reader.readAsBinaryString(file);

    };
    render() {

        return AddStudentTemplate.call(this);
    }

}