

import  React from  "react";
import { Route, Redirect } from  "react-router-dom";
const loggedIn = window.sessionStorage.getItem("loginUser");
const  PrivateRoute: React.FC<{
        component: any;
        path: string;
        exact: boolean;
    }> = (props: any) => {

    // const condition = performValidationHere();

    return  loggedIn ? (<Route  path={props.path}  exact={props.exact} component={props.component} />) : 
        (<Redirect  to="/"  />);
};

export  default  PrivateRoute;

// /**
//  *
//  *
//  * @return {*}  {boolean}
//  */
// function performValidationHere(): boolean {
//     return false
// }
