/* eslint-disable */

import fetchIntercept from 'fetch-intercept';
import {httpMethods} from '../constants/httpEnum';

export class HttpService {
    public loggedIn: any = window.sessionStorage.getItem("loginUser");

    constructor() { }

     unregister = fetchIntercept.register({
        response: function (response) {
            if(response.status === 401){
                window.location.href = '/session-timeout';
                // location.reload();
        }
            return response;
        },
    
        responseError: function (error) {
            if(error.status === 401){
                window.location.href = '/session-timeout';
                // location.reload();
        }
             return Promise.reject(error);
        }
    });

    public httpInterceptor(method: string, requestData?: any) {
        switch (method) {
            case httpMethods.GET : return {
                method: httpMethods.GET,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + ' ' + JSON.parse(this.loggedIn).token
                }
            };
            case httpMethods.POST:
                return {
                    method: httpMethods.POST,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer' + ' ' + JSON.parse(this.loggedIn).token
                    },
                    body: JSON.stringify(requestData)
                };

        }

    }
}