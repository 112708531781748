


import React from 'react';
import { RoleEnum } from '../../../../../common/constants/role-enum';
import SideNav from '../../../side-nav/side-nav';

export const CohortMainTemplate = function (this: any) {
    return (



        <div className="main-container d-flex">
             {  this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">Cohorts</h2>
                            {this.state.showPopup ? 
                            <div className="modal-FormatCls medium-modal-FormatCls">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Cohort Code</h5>
                                            <span className="close" onClick={() => this.closePopup()}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div>
                                        <div className="modal-body">
                                        <div className="Cohorts-link-box">
                                        <h6>Please share this code with the students to join the cohort.</h6>
                                        {/* <p>Code : {this.state.link}</p> */}
                                        <div className="d-flex align-items-center">
                                        <div className={this.state.isCopied ? 'success-bg inner-box' : 'inner-box'}>
                                            <span className="d-block mr-2">Code : </span> 
                                            <a className="d-block">{this.state.link}
                                            </a>
                                        </div>
                                        <div className="copy-icon-box">
                                        <a className="copy-icon" onClick={() => this.copyText(this.state.link)}></a>
                                        </div>
                                        </div>
                                    </div>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                               
                                        </div>
                                    </div>
                                </div>



                                {/* <div className="box">
                                    <span className="close-icon" onClick={() => this.closePopup()}>x</span>
                                    <div className={this.state.isCopied ? 'green-bg inner-box' : 'inner-box'}>
                                        <h6><u>Please share this link with the students to join the cohort.</u></h6>
                                        <span>Code : {this.state.link}</span>
                                        <span className="d-flex">  Link : <a className="d-flex">{this.PROD_URL + btoa(this.state.link)}<a className="copy-icon" onClick={() => this.copyText(this.PROD_URL + btoa(this.state.link))}></a></a></span>
                                    </div>
                                </div> */}
                            </div> : ''}
                            <div className="add-btn d-flex">
                            <span className="create-text">  Create New</span><a  className="add-box" onClick={this.addCohert}> <span  className="add-icon"></span></a>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <div className="tableformatCls">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Cohort</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Sequence</th>
                                        <th scope="col">Status</th>
                                     {RoleEnum.Admin === this.userInfo.role  ?    <th scope="col">Created By</th> : '' }
                                        <th scope="col">Code</th>
                                       
                                        <th className="text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state && this.state.data.length ? this.state.data.map((listValue: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{listValue.cohortName}</td>
                                                <td>{this.dateFormatService.convertData(listValue.startDate)}</td>
                                                <td>{this.dateFormatService.convertData(listValue.endDate)}</td>
                                                <td>{listValue.sequence}</td>
                                                <td className={new Date(listValue.endDate) < new Date() ? 'inactive-cohort':'active-cohort'}>{ new Date(listValue.endDate) < new Date() ? 'Inactive' : 'Active'}</td>
                                                {RoleEnum.Admin === this.userInfo.role  ? <td>{listValue.advisorName}</td> : ''}
                                                <td>
                                                    {/* <a className="cursor" onClick={() => this.showPopUp(listValue.cohortCode)}>Code</a> */}
                                                    <div className="d-flex align-items-center">
                                                    <div className={listValue.isCopied ? 'success-bg inner-box' : 'inner-box'}>
                                                        <span className="d-block">{listValue.cohortCode}
                                                        </span>
                                                    </div>
                                                    <div className="copy-icon-box">
                                                        <a className="copy-icon" onClick={() => this.copyText(listValue.cohortCode)}></a>
                                                    </div>
                                                </div>
                                                </td>

                                                <td>
                                                <ul className="action-icon d-flex justify-content-center">
                                                        <li><a className="edit-icon" onClick={() => this.editCohort(listValue)} ></a></li>
                                                        <li><a className="delete-icon" onClick={() => this.deleteCohort(listValue._id,index,listValue)}></a></li>
                                                    </ul>
                                                    {/* <span className="edit-icon" onClick={() => this.editCohort(listValue)}></span>
                                                    <span className="del-icon" onClick={() => this.deleteCohort(listValue._id,index)}></span> */}
                                                </td>
                                            </tr>
                                        );
                                    }) : 
                                    <tr>
                                    <td className="text-center" colSpan={9}>No Data Available</td>
                                </tr>
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};
