import React from 'react';
import SideNav from '../../../side-nav/side-nav';
import { ButtonGroup, Button } from 'react-bootstrap';
import "./add-student.scss";
import { CSVLink } from "react-csv";

/* eslint-disable */
export const AddStudentTemplate = function (this: any) {
    return (


        <div className="main-container d-flex">
            {  this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title"> Add Student</h2>
                            <a className="back-btn" onClick={this.onFormCancel}><span className="back-icon"></span>Back</a>
                        </div>
                        <div className="separator"></div>
                        {this.state.duplicateEmails.length ?
                            <div className="conformation-modal modal fade-bg"  >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Confirmation Box</h5>
                                            <span className="close" onClick={this.onFormCancel}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div>
                                        <div className="modal-body">
                                            <div >
                                                <label className="label">Following Email(s) already exists</label>
                                                <div className="inputgroup mb-0">
                                                {this.state.duplicateEmails.map((item: any, index: number) => {
                                                    return (<div className="form-control info-form" key={index}>
                                                        {item}
                                                    </div>)
                                                })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                        <div className="separator"></div>
                                                <div className="text-center">
                                                    <button type="button" className="btn btn-primary" onClick={this.removeDuplicates}>Remove</button>
                                                </div>

                                            </div>
                                    </div>
                                </div>
                            </div> : ''
                        }
                        <div className="tabFormatCls">
                            <div className="tab-header">
                            <ButtonGroup aria-label="Basic example">
                                    <Button variant={(this.state.isBulkUpload) ? 'btn' : 'tab-btn active-btn'} onClick={this.changeTab}>Individual</Button>
                                    <Button variant={(this.state.isBulkUpload) ? 'btn active-btn' : 'btn'} onClick={this.changeTab}>Bulk</Button>

                            </ButtonGroup>
                            </div>
                            <form className="tab-panel">
                            <div>
                                {this.state.isBulkUpload ? 
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="inputgroup">
                                            <React.Fragment>
                                                <input
                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    ref={this.myRef}
                                                    id="fileInput"
                                                    className="upload-hide"
                                                    onChange={this.handleFileUpload}
                                                    type="file"
                                                    multiple={false}
                                                />
                                                <label className="form-control" htmlFor="fileInput">
                                                    <span>{this.state.fileName ? this.state.fileName  : 'Upload'}</span>
                                                </label>

                                            </React.Fragment>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <CSVLink className="link-title mt-2 pt-1 d-block"
                                            filename="Student-bulk-upload-template.csv"
                                            data={this.csv}>*Download Template</CSVLink>
                                    </div>
                                </div> : ''}
                                <div className="row">
                                    <div className="col-md-6">
                                    {this.state.isBulkUpload ? '' : <div>
                                    <div className="inputgroup">
                                            <label className="label"> First Name </label>
                                            <input type="text" name="firstName" placeholder="Enter First Name"
                                                value={this.state.firstName} onChange={this.onchanges}
                                                className="form-control" required />
                                            {this.state.errormessage}
                                        </div>
                                    </div>}
                                    </div>
                                    <div className="col-md-6">
                                    {this.state.isBulkUpload ? '' : <div>
                                    <div className="inputgroup">
                                            <label className="label"> Last Name </label>
                                            <input type="text" name="lastName" placeholder="Enter Last Name"
                                                value={this.state.lastName} onChange={this.onchanges}
                                                className="form-control" required />
                                            {this.state.errormessage}
                                        </div>
                                    </div>}
                                    </div>
                                    <div className="col-md-6">
                                    {this.state.isBulkUpload ? '' : <div>
                                    <div className="inputgroup">
                                            <label htmlFor="formGroupExampleInput" className="label">Email</label>
                                            <input type="text" value={this.state.email} onChange={this.onchanges}
                                                name="email"
                                                className="form-control" id="formGroupExampleInput" placeholder="Enter Email Address"></input>
                                        </div> 
                                    </div>}
                                    </div>
                                    <div className="col-md-6">
                                    {this.state.isBulkUpload ? '' : <div>
                                    <div className="inputgroup">
                                            <label htmlFor="formGroupPhoneNumber" className="label">Phone Number</label>
                                            <input type="text" value={this.state.phoneNumber} onChange={this.onchanges}
                                                name="phoneNumber"
                                                className="form-control" id="formGroupPhoneNumber" placeholder="Enter Phone Number"></input>
                                        </div>
                                    </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                    <div className="separator"></div>
                                    </div>
                                </div>
                                
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <button className="btn btn-primary mr-3" type="submit"
                                            onClick={this.submitForm}>Save</button>
                                    </div>
                                    <div>
                                        <button className="btn btn-primary" type="button"
                                            onClick={this.onFormCancel}
                                        >Cancel</button>
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    {this.state.isBulkUpload ? '' : <div>
                                        <div className="inputgroup">
                                            <label className="label"> First Name </label><br />
                                            <input type="text" name="firstName" placeholder="Enter First Name"
                                                value={this.state.firstName} onChange={this.onchanges}
                                                className="form-control" required />
                                            {this.state.errormessage}
                                        </div>

                                        <div className="inputgroup">
                                            <label className="label"> Last Name </label><br />
                                            <input type="text" name="lastName" placeholder="Enter Last Name"
                                                value={this.state.lastName} onChange={this.onchanges}
                                                className="form-control" required />
                                            {this.state.errormessage}
                                        </div>
                                        <div className="inputgroup">
                                            <label htmlFor="formGroupExampleInput" className="label">Email</label>
                                            <input type="text" value={this.state.email} onChange={this.onchanges}
                                                name="email"
                                                className="form-control" id="formGroupExampleInput" placeholder="Enter Email Address"></input>
                                        </div>  
                                        <div className="inputgroup">
                                            <label htmlFor="formGroupPhoneNumber" className="label">Phone Number</label>
                                            <input type="text" value={this.state.phoneNumber} onChange={this.onchanges}
                                                name="phoneNumber"
                                                className="form-control" id="formGroupPhoneNumber" placeholder="Enter Phone Number"></input>
                                        </div>
                                    </div>}
                                    <br />
                                    <div className="d-flex">
                                        <div className="col-2">
                                            <button className="btn btn-primary w-5" type="submit"
                                                onClick={this.submitForm}>Save</button>
                                        </div>
                                        <div className="col-2">
                                            <button className="btn btn-primary w-5" type="button"
                                                onClick={this.onFormCancel}
                                            >Cancel</button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </form>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>

    )
};
