/* eslint-disable */
import React, { Component, lazy, useState } from 'react';
import { ForgotPasswordTemplate } from './forgot-password-template';

import { API_URL } from '../../../common/constants/url';
import { toast } from 'react-toastify';
import history from '../../../config/router/history';
import { ToasterService } from '../../../common/services/toastr-service';
import {DataService} from '../../../common/services/data-service'; 
//import notify from "../../../common/services/toastr-service";
export default class Login extends Component {
    public toasterService = new ToasterService();
    public dataService = new DataService();
    public error = null;
    public loading = false;
    public history: any;
    constructor(props: any) {
        super(props)
        window.sessionStorage.clear();
        this.onchanges = this.onchanges.bind(this);
        this.verifyEmail = this.verifyEmail.bind(this);
        this.state = {
            loader: false,
            username: '',
            showPopup: false
        }
    }

    public goToLogin() {
        window.location.href = "/";
    }

    public onchanges(event: any) {
        switch (event.target.name) {
            case 'username': this.setState({ username: event.target.value }); break;
        }
    }

    public verifyEmail(sendEmail: boolean) {
        this.dataService.verifyEmail(sendEmail,this.state);
    }



    render() {
        return ForgotPasswordTemplate.call(this);
    }
}



