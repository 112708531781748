

/* eslint-disable */
import React, { useState, Component } from 'react';
import { RequestsTemplate } from './requests-template';

import { API_URL } from '../../../common/constants/url';
import { DateFormatService } from '../../../common/services/date-format-service';
import { ToasterService } from '../../../common/services/toastr-service';
import { RoleEnum } from '../../../common/constants/role-enum';
import { httpMethods } from '../../../common/constants/httpEnum';
import { HttpService } from '../../../common/services/http-service';


export default class SequenceMain extends Component {
    public toasterService = new ToasterService();
    public httpService = new HttpService();
    public loggedIn = window.sessionStorage.getItem("loginUser");
    public userInfo = this.loggedIn && typeof this.loggedIn === 'string' ?
        JSON.parse(this.loggedIn).userInfo : this.loggedIn;
    public dateFormatService = new DateFormatService();
    constructor(props: any) {
        super(props);
        this.state = {
            loader: false,
            requestsData: []
        }
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        const stateData: any = this.state;
        if (!stateData.requestsData.length && this.userInfo.role !== RoleEnum.Admin) {
            this.getRequestsData();
        }
    }

    public changeStatus(event: any, rowData: any) {
        rowData["status"] = event.target.name;
        this.setState({ loader: true });
        fetch(API_URL + 'addeditrequest',
            this.httpService.httpInterceptor(httpMethods.POST, rowData))
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok && !response.headers) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                if (data && data.payload && !data.error) {
                    this.toasterService.notify('success', 'Status Updated Successfully');
                    // this.setState({ requestsData: [...data.payload] });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else if (data.error) {
                    this.toasterService.notify('error', data.error);
                }
            })
            .catch(error => {
                this.setState({ loader: false });
                //   this.toasterService.notify('error', 'Login Failure');
            });
    }

    public getRequestsData() {
        this.setState({ loader: true });
        let requestData = {};
        if (this.userInfo.role === RoleEnum.Student) {
            requestData = { requestedBy: this.userInfo.email }
        } else {
            requestData = { requestedTo: this.userInfo.email }
        }
        fetch(API_URL + 'getrequestsdata',
            this.httpService.httpInterceptor(httpMethods.POST, requestData))
            .then(async response => {
                this.setState({ loader: false });
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const resdata = isJson && await response.json();
                if (resdata && resdata.payload) {
                    this.setState({ requestsData: [...resdata.payload] });
                }
            })
            .catch(error => {
                this.setState({ loader: false });
            });
    }

    render() {
        return RequestsTemplate.call(this);
    }
}