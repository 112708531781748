/* eslint-disable */
import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { RoleEnum } from '../../../common/constants/role-enum';
export const ProfileTemplate = function (this: any) {


return (
    <div className="main-container">
        <div className="container-fluid w-100">
            <div className="box-wrapper">
                <div className="d-flex justify-content-between align-items-center">
                    <h2 className="sub-title">Profile</h2>
                    <a className="back-btn"  onClick={this.onFormCancel}><span className="back-icon"></span>Back</a>
                </div>
                <div className="separator"></div>
                <form className="w-100">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="inputgroup">
                                <label className="label"> First Name </label>
                                <input type="text" name="firstName" placeholder="Enter First Name"
                                    value={this.state.firstName} onChange={this.onchanges}
                                    className="form-control" required />
                                {this.state.errormessage}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inputgroup">
                                <label className="label"> Last Name </label>
                                <input type="text" name="lastName" placeholder="Enter Last Name"
                                    value={this.state.lastName} onChange={this.onchanges}
                                    className="form-control" required />
                                {this.state.errormessage}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inputgroup">
                                <label className="label"> Role</label>
                                <input type="text" name="role"
                                    value={this.userInfo.role} 
                                    className="form-control" disabled/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inputgroup">
                                <label htmlFor="formGroupExampleInput" className="label">Email</label>
                                <input type="text" value={this.state.email} onChange={this.onchanges}
                                    name="email" disabled
                                    className="form-control" id="formGroupExampleInput" placeholder="Enter Email Address"></input>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inputgroup">
                                <label className="label"> Phone Number </label>
                                <input type="text" name="phoneNumber" placeholder="Enter Phone Number"
                                    value={this.state.phoneNumber} onChange={this.onchanges}
                                    className="form-control" required />
                                {this.state.errormessage}
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                        
                        { this.userInfo.role === RoleEnum.Advisor ? 
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="label">ProjectYou would like to access your calendar,
                                        Please select from below :</label>
                                        <div className="d-flex flex-wrap mt-3">
                                            {
                                                this.calendarList.map((category:any) => {
                                                    return (
                                                    
                                                        <div key={category.key} className="p-field-radiobutton d-flex align-items-center mr-5">
                                                            <RadioButton inputId={category.key} name="category" 
                                                            value={category} 
                                                            onChange={(e:any) => this.setState({selectedCalendar:e.value.key}) }
                                                            
                                                                checked={this.state.selectedCalendar === category.key}
                                                                />
                                                            <label className="label mb-0 ml-2" htmlFor={category.key}>{category.name}</label>
                                                        
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                

                            </div>
                        </div> : ''}


                        { this.userInfo.role === RoleEnum.Advisor ? 
                        <div className="col-md-6">
                            <div className="mb-3">
                                {this.state.selectedCalendar && this.state.selectedCalendar === 'Google Calender' ? 
                                <div className="inputgroup">
                                    <label className="label"> Please Paste the Calendar Link below,</label>
                                    <input type="text" name="iFrame" placeholder="Enter the Link"
                                        value={this.state.iFrame} onChange={this.onchanges}
                                        className="form-control" />
                                    
                                </div> : ''}

                            </div>
                        </div> : ''}
                        { this.userInfo.role === RoleEnum.Advisor ?  
                        <div className="row w-100">
                           
                        <div className="col-md-12" >
                        
                            <div className="box-wrapper Calendar-info">
                                <h3 className="Calendar-title">Steps to Access Calendar Link</h3>
                                <div className="info-list">
                                    <p> 1) On a computer, open Google Calendar.
                                        You can only get the code to embed in your website from a computer, not the Google Calendar app.    </p>
                                    <p> 2) On the top-right, click Settings Icon and then select Settings.   </p>
                                    <p> 3) On the left side of the screen, click the name of the calendar you want to embed.   </p>
                                    <p> 4) In the "Integrate calendar" section, copy the iframe code displayed in the Embed Code.    </p>
                                </div>
                            </div>
                            
                  
                            
                        </div>
                        </div> : ''}
                        <div className="col-md-12">
                        <div className="separator"></div>
                            <div className="text-center">
                                <button className="btn btn-primary mr-3" type="button"
                                        onClick={this.submitForm}>Save</button>
                                <button className="btn btn-primary" type="button"
                                onClick={this.onFormCancel}
                                >Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
       
    </div>

)
}
