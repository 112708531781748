

/* eslint-disable */
import React, { useState, Component } from 'react';
export default class SessionTimeout extends Component {
    
    constructor(prop:any){
        super(prop);
    }

    componentDidMount(){
        window.sessionStorage.clear(); 
    }

 
    render() {
        return (
         
            <div className="error-main">
              <div className="error-wrapper d-flex justify-content-center align-items-center flex-column h-100">
                  <div className="icon-box">
                      <h2 className="d-flex  align-items-centerx"><a className="timeout-icon"></a>401</h2>
                  </div>
                  <div className="error-info-box">
                      <h3>Session Timeout</h3>
                      <p>Your Session has expired. Please login again.</p>
                      <a className="btn btn-primary error-btn" onClick={() => {window.location.href='/'}}>Go To Login Page</a>
                  </div>
                  <div className="error-footer" style={{cursor:'pointer'}}>
                      <a className="error-title" onClick={() =>{window.location.href='https://www.myprojectyou.com/'}}><span className="title-spacing">ProjectYou - {new Date().getFullYear()}</span> | 
                      <span className="action-title">Terms of Use</span> | <span className="action-title">Privacy Statement</span></a>
                  </div>
              </div>
              
            </div>
            
                )
    }
}
