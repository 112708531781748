import React from 'react';
import SideNav from '../../../side-nav/side-nav';

export const SequenceMainTemplate = function (this: any) {
    return (

        <div className="main-container d-flex">
             {  this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">Sequence</h2>

                            <div className="add-btn d-flex">
                                <span className="create-text">  Create New</span><a className="add-box" onClick={this.addSequence}> <span className="add-icon"></span></a>
                            </div>
                        </div>
                        <div className="separator"></div>
                        <div className="tableformatCls">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"> No. of Session(s)</th>
                                        <th scope="col">Start Date</th>

                                        <th className="text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state && this.state.sequenceData.length ? this.state.sequenceData.map((listValue: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{listValue.sequence}</td>
                                                <td><div className="">
                                                    {listValue.events.length}</div></td>
                                                {/* <td>{this.dateFormatService.convertData(_.maxBy(listValue.events, 'dateOfEvent'))}</td> */}
                                                <td>{this.transformDate(listValue.events)}</td>
                                                <td>
                                                <ul className="action-icon d-flex justify-content-center">
                                                        <li><a className="edit-icon" onClick={() => this.editSequence(listValue)} ></a></li>
                                                        <li><a className="delete-icon" onClick={() => this.deleteSequence(listValue._id,index,listValue.events)}></a></li>
                                                    </ul>

                                                    {/* <span onClick={() => this.editSequence(listValue)} className="edit-icon"></span>
                                                    <span className="del-icon" onClick={() => this.deleteSequence(listValue._id,index)}></span> */}
                                                </td>
                                            </tr>
                                        );
                                    }) : <tr>
                                    <td className="text-center" colSpan={5}>No Data Available</td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
