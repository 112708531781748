/* eslint-disable */
export enum RoleEnum {
    Admin = 'Admin',
    Advisor = 'Advisor',
    Student = 'Student',
}

export enum SessionType {
    _CohortSession = 'Cohort Session',
     _1on1Session  = '1:1'
}