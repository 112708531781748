/* eslint-disable */
import React, { Component, useState } from 'react';
import { logout, isLogin } from '../../../../../utils';
import { RoleEnum } from '../../../../../common/constants/role-enum';
import { CohortMainTemplate } from './cohort-main-template';
import { withRouter } from 'react-router-dom';
import { API_URL, PROD_URL } from '../../../../../common/constants/url';
import { toast } from 'react-toastify';
import history from '../../../../../config/router/history';
import { DataService } from '../../../../../common/services/data-service';
import { DateFormatService } from '../../../../../common/services/date-format-service';
import { ToasterService } from '../../../../../common/services/toastr-service';
import { HttpService } from '../../../../../common/services/http-service';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import {httpMethods} from '../../../../../common/constants/httpEnum';
export default class CohortMain extends Component {
  public dataService = new DataService();
  public toasterService = new ToasterService();
  public dateFormatService = new DateFormatService();
  public httpService = new HttpService();
  public API_URL = API_URL;
  public PROD_URL = PROD_URL;
  public loggedIn:any = window.sessionStorage.getItem("loginUser");
  public userInfo = this.loggedIn ? JSON.parse(this.loggedIn).userInfo : '';
  constructor(props: any) {
    super(props);
    this.dataService.setCohortData(undefined);
    this.state = {
      loader: false,
      isLogin: isLogin(),
      data: [],
      showPopup: false,
      link: '',
      isCopied: false
    }
    let copyloggedIn: any = this.loggedIn;
    if (_.isString(copyloggedIn)) {
      copyloggedIn = JSON.parse(copyloggedIn);
    }
    copyloggedIn["editData"]["editCohort"] = null;
    window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
    this.addCohert = this.addCohert.bind(this);
    this.editCohort = this.editCohort.bind(this);
    this.deleteCohort = this.deleteCohort.bind(this);
  }

  componentDidMount() {
    const stateData: any = this.state;
    if (!stateData.data.length) {
      this.getCohortData();
    }
  }

  public deleteCohort(id: number, rowIndex: number,rowData:any) {

    if(rowData &&
      new Date() <= new Date(rowData.endDate)  && 
      new Date() >= new Date(rowData.startDate)) {
       this.toasterService.notify('error', 'Cannot Delete an Active Cohort');
       return;
      }else{
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='conformation-popup'>
            <h1>Are you sure?</h1>
            <p>You want to delete this item?</p>
            <button className="confirm-btn" onClick={onClose}>No</button>
            <button className="confirm-btn"
              onClick={() => {
                this.setState({ loader: true });
                const stateData: any = this.state;
                if (stateData.data.length) {
                  fetch(API_URL + 'deletecohort', 
                  this.httpService.httpInterceptor(httpMethods.POST,{
                    _id: id ? id : null}))
                    .then(async response => {
                      this.setState({ loader: false });
                      if (!response.ok && !response.headers) {
                        throw Error(response.statusText);
                      }
                      const isJson = response.headers.get('content-type')?.includes('application/json');
                      const data = isJson && await response.json();
                      if (data && data.payload) {
                        this.toasterService.notify('success', 'Cohort Successfully Deleted');
                        stateData.data.splice(rowIndex, 1);
                        this.setState({ data: [...stateData.data] });
                        onClose();
                      } else {
                        this.toasterService.notify('error', 'Failed to Delete Cohort');
                        onClose();
                      }
                    })
                    .catch(error => {
                      this.setState({ loader: false });
                      //   this.toasterService.notify('error', 'Login Failure');
                    });
                } else {
                  this.setState({ loader: false });
                }
              }}
            >
              Yes, Delete it!
  </button>
          </div>
        );
      }
    });
  }
  }
  public addCohert() {
    history.push('/add-cohort');
    window.location.reload();
  }
  public editCohort(rowData: any) {
    if (rowData) {
      let copyloggedIn: any = this.loggedIn;
      if (_.isString(copyloggedIn)) {
        copyloggedIn = JSON.parse(copyloggedIn);
      }
      copyloggedIn["editData"]["editCohort"] = { ...rowData };
      this.dataService.setCohortData(rowData);
      window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
      history.push('/add-cohort');
      window.location.reload();
    }
  }

  public getCohortData() {
    fetch(API_URL + 'getCohortData', this.httpService.httpInterceptor(httpMethods.GET))
      .then(async response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();

        if (resdata && resdata.payload) {
          this.setState({ data: [...resdata.payload] });
        }
      })
      .catch(error => {
        //   notify('error', 'Login Failure');
      });
  }


  public showPopUp(link: any) {
    this.setState({ link: link, isCopied: false, showPopup: true });
  }
  public closePopup() {
    this.setState({ link: '', isCopied: false, showPopup: false });
  }

  public copyText(text: any) {
    var input: any = document.body.appendChild(document.createElement("input"));
    input.value = text;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);
    this.setState({ isCopied: true });
    this.toasterService.notify('success', 'Copied');
  }

  render() {
    return CohortMainTemplate.call(this);
  }
}






