import React from 'react';
import { RoleEnum } from '../../../common/constants/role-enum';
import SideNav from '../side-nav/side-nav';

export const RequestsTemplate = function (this: any) {
    return (

        <div className="main-container d-flex">
             {  this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <SideNav />
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">Requests</h2>

                            
                        </div>
                        <div className="separator"></div>
                        <div className="tableformatCls">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col"> Cohort</th>
                                        <th scope="col">Session</th>
                                        <th scope="col">Session Start Date</th>
                                        <th scope="col">Start - End (Time)</th>
                                      { this.userInfo?.role === RoleEnum.Advisor ?  <th scope="col">Requested By</th> : ''}
                                        <th scope="col">Status</th>
                                        { this.userInfo?.role === RoleEnum.Advisor ?  <th scope="col">Actions</th> : ''}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state && this.state.requestsData.length ? this.state.requestsData.map((listValue: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{listValue.cohortName}</td>
                                                <td><div className="">{listValue.session}</div></td>
                                                <td>{this.dateFormatService.convertData(listValue.dateOfEvent)}</td>
                                                <td> {this.dateFormatService.convertTime(listValue.startTimeOfEvent) + ' - ' +
                                                 this.dateFormatService.convertTime(listValue.endTimeOfEvent)}</td>
                                                { this.userInfo?.role === RoleEnum.Advisor ?   <td><div className="">{listValue.studentName}</div></td> :''}
                                                <td><span className={(listValue.status === 'Pending For Approval') ?
                                                 'normal-text' : ((listValue.status === 'Approved')? 'green-text': 'red-text')}>{listValue.status }</span></td>
                                           
                                               { this.userInfo?.role === RoleEnum.Advisor ?  <td className="d-flex">
                                                    <span>  <button  onClick={(e) => this.changeStatus(e,listValue)}
                                                    name="Approved" type="button" disabled={listValue.status === 'Approved'}
                                                 
                                                    className={(listValue.status === 'Pending For Approval')? 'btn btn-primary green-btn' : ' btn btn-primary disabled-btn'}>Approve</button></span>
                                                    <span style={{marginLeft:"5px"}}> <button  onClick={(e) => this.changeStatus(e,listValue)}
                                                    name="Rejected" type="button"  disabled={listValue.status === 'Approved'}
                                                    className={(listValue.status === 'Pending For Approval')? 'btn btn-primary red-btn' : ' btn btn-primary disabled-btn'}>Reject</button></span>
                                                  
                                                   
                                                </td> : '' }
                                            </tr>
                                        );
                                    }) : <tr>
                                    { this.userInfo?.role === RoleEnum.Advisor ? 
                                     <td className="text-center" colSpan={8}>No Data Available</td> : ''}
                                   { this.userInfo?.role === RoleEnum.Student ? 
                                    <td className="text-center" colSpan={6}>No Data Available</td> : ''}
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};