/* eslint-disable */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { FooterTemplate } from './footer-template';

export default class Footer extends Component {
    public loggedIn = window.sessionStorage.getItem("loginUser");
    public name = this.loggedIn ? JSON.parse(this.loggedIn).userInfo?.email : '';
    constructor(props: any) {
        super(props);
      }


    render() {
        return this.loggedIn ?  FooterTemplate.call(this) : '';
    }
}
