/* eslint-disable */
import React, { Component } from 'react';
import { SideNavTemplate } from './side-nav-template';
import history from '../../../config/router/history';
import { RoleEnum } from '../../../common/constants/role-enum';
import { API_URL } from '../../../common/constants/url';
import { HttpService } from '../../../common/services/http-service';
import { httpMethods } from '../../../common/constants/httpEnum';
export default class SideNav extends Component {
  public loggedIn: any = window.sessionStorage.getItem("loginUser");
  public userInfo = this.loggedIn ? JSON.parse(this.loggedIn).userInfo : '';
  public iscollapsed = false;
  public httpService = new HttpService();
  constructor(props: any) {
    super(props);
    this.changePage = this.changePage.bind(this);
    this.getRequestsData = this.getRequestsData.bind(this);
    this.state = this.loggedIn && JSON.parse(this.loggedIn).navData ? JSON.parse(this.loggedIn).navData : {};
  }
  componentDidMount() {
    const stateData: any = this.state;

    if (stateData && stateData.requestsData === undefined) {
      this.getRequestsData();
    }
  }

  public getRequestsData() {
    this.setState({ loader: true });
    let requestData = {}

    if (this.userInfo.role === RoleEnum.Student) {
      requestData = { requestedBy: this.userInfo.email }
    } else {
      requestData = { requestedTo: this.userInfo.email }
    }

    fetch(API_URL + 'getrequestsdata',
      this.httpService.httpInterceptor(httpMethods.POST, requestData))
      .then(async response => {
        this.setState({ loader: false });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const resdata = isJson && await response.json();
        if (resdata && resdata.payload) {
          this.setState({ requestsData: resdata.payload.filter((x: any) => x.status === 'Pending For Approval') });
        }
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  public changePage(path?: any) {
    if (path) {
      const copyloggedIn = JSON.parse(this.loggedIn)
      copyloggedIn["navData"] = {
        advisorsLink: false, cohortsLink: false, sequncesLink: false,
        dashboardLink:false, supportLink:false,
        studentsLink: false, settingLink: false, requestsLink: false
      };
      copyloggedIn["navData"][path] = true;
      window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
      this.setState({
        advisorsLink: false, cohortsLink: false, requestsLink: false,
        dashboardLink:false, supportLink:false,
        sequncesLink: false, studentsLink: false, settingLink: false
      });
      this.setState({ [path]: true });
      switch (path) {
        case 'dashboardLink' : this.goToDashboard(); break;
        case 'advisorsLink': this.gotToAdvisors(); break;
        case 'cohortsLink': this.gotToCohorts(); break;
        case 'sequncesLink': this.goToSequences(); break;
        case 'studentsLink': this.goToStudents(); break;
        case 'settingLink': this.goToSettings(); break;
        case 'requestsLink': this.goToRequests(); break;
        case 'supportLink': this.goToSupport(); break;
      }
    } else {
      let state: any = this.state;
      if (state.iscollapsed) {
        this.setState({ iscollapsed: false });
      } else {
        this.setState({ iscollapsed: true });
      }
    }


  }

  public goToDashboard() {
    window.location.href = "/dashboard"
  }

  public goToStudents() {
    window.location.href = "/students-main"
  }

  public gotToCohorts() {

    window.location.href = "/cohort-main"
  }

  public gotToAdvisors() {
    window.location.href = "/advisors-main"
  }

  public goToSequences() {

    window.location.href = "/sequence-main"
  }

  public goToSettings() {

    window.location.href = "/settings"
  }
  public goToRequests() {
    window.location.href = "/requests"
  }
  public goToSupport() {
    window.location.href = "/support"
  }

  render() {
    return SideNavTemplate.call(this);
  }
}