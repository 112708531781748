
/* eslint-disable */
import { Component, useState } from 'react';
import { HeaderTemplate } from './header-template';
import history from '../../../config/router/history';
import {RoleEnum} from '../../../common/constants/role-enum';
export default class Header extends Component {
  public loggedIn: any = window.sessionStorage.getItem("loginUser");
  public name: any = this.loggedIn &&  JSON.parse(this.loggedIn).userInfo &&JSON.parse(this.loggedIn).userInfo.name ?
  JSON.parse(this.loggedIn).userInfo.name : (
    this.loggedIn && JSON.parse(this.loggedIn).userInfo?.fullName ?  JSON.parse(this.loggedIn).userInfo?.fullName : ''
  )
  public role = this.loggedIn && typeof this.loggedIn === 'string' ?
  JSON.parse(this.loggedIn).userInfo?.role : '';

  constructor(props: any) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.goToDashboard = this.goToDashboard.bind(this);
    this.state = {
      isToggle: false
    }
  }
  public toggle() {
    const state: any = this.state;
    if (state.isToggle) {
      this.setState({ isToggle: false });
    } else {
      this.setState({ isToggle: true });
    }
  }

  public logout() {
    window.sessionStorage.clear();
    history.push('/')
    window.location.reload();
  }

  public goToProfile(){
    window.location.href = '/profile';
  }

  public goToDashboard() {
    const copyloggedIn = JSON.parse(this.loggedIn)
    copyloggedIn["navData"] = {
      advisorsLink: RoleEnum.Admin === this.role ?  true : false, cohortsLink: false, sequncesLink: false,
      studentsLink: false, settingLink: false, requestsLink: false ,supportLink:false,
      dashboardLink: RoleEnum.Admin !== this.role ? true : false
    };
    window.sessionStorage.setItem("loginUser", JSON.stringify(copyloggedIn));
    if(RoleEnum.Admin === this.role) {
      window.location.href = '/advisors-main';
    }else{
      window.location.href = '/dashboard';
    }

  }

  public goToSettings() {
    history.push('/settings');
    window.location.reload();
  }
  render() {

    return this.loggedIn ? HeaderTemplate.call(this) : '';
    //  return HeaderTemplate.call(this);
  }


}
