

/* eslint-disable */

export const emailRegEx : RegExp =
 /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
  export const nameRegEx : RegExp = /^[a-zA-Z _.-]*$/;


   


  