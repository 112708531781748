
export const FooterTemplate = function (this: any) {
    return (

        <div className="footer">
            <div className="footer-wrapper">
                <a> ProjectYou - 2021 | Terms of Use | Privacy Statement </a>
            </div>
        </div>
    )
};