import React from 'react';
import SideNav from '../side-nav/side-nav';
import { Editor } from 'primereact/editor';
export const SettingsTemplate = function (this: any) {
    return (

        <div className="main-container d-flex">
            <SideNav />
            {this.state.loader ?
                <div className="loader-wrapper">
                    <div className="loader">Loading...</div>
                </div> : ''
            }
            <div className="right-container-panel d-flex align-items-stretch">
                <div className="container-fluid w-100">
                    <div className="box-wrapper h-100">
                        {this.state.showTemplate ?
                            <div className="modal-FormatCls popup-box">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">{this.state.templateName}</h5>
                                            <span className="close" onClick={e => this.setState({
                                                showTemplate: false, text: '', templateName: ''
                                            })}>
                                                <span aria-hidden="true">&times;</span>
                                            </span>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="editorFormatCls">
                                                        <Editor style={{ height: '320px' }} readOnly={this.state.disableEditor}
                                                            value={this.state.text} onTextChange={(e) => this.setText(e.htmlValue)} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                            <div className="separator"></div>
                                            {this.state.disableEditor ? '' :
                                                <button className="btn btn-primary"
                                                    onClick={this.saveTemplate}>Save</button>}
                                            <button className="btn btn-primary"
                                                onClick={e => this.setState({
                                                    showTemplate: false, text: '', templateName: ''
                                                })}>{this.state.disableEditor ? 'Back' : 'Cancel'}</button>
                                        </div>
                                    </div>
                                </div>

                               
                            </div> : ''}
                        <div className="d-flex justify-content-between align-items-center">
                            <h2 className="sub-title">Email Templates</h2>

                        </div>

                        <div className="separator"></div>
                        <div className="tableformatCls">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th className="text-center" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state && this.state.templateData.length ? this.state.templateData.map((listValue: any, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{listValue.templateName}</td>

                                                <td>
                                                    <ul className="action-icon d-flex justify-content-center">
                                                        <li><a className="edit-icon" onClick={() => this.editViewTemplate(listValue, index)}></a></li>
                                                        <li><a className="view-icon" onClick={() => this.editViewTemplate(listValue)}></a></li>
                                                    </ul>

                                                </td>
                                            </tr>
                                        );
                                    }) : <tr>
                                    <td className="text-center" colSpan={3}>No Data Available</td>
                                </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
